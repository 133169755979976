// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._sheet {
  z-index: 999 !important;
}
._sheet .react-modal-sheet-header {
  height: 20px !important;
}
._sheet .react-modal-sheet-header span {
  width: 24px !important;
}
._sheet .react-modal-sheet-container {
  border-radius: 16px 16px 0 0 !important;
}
._sheet .head-scroll {
  border-bottom: 1px solid var(--Gray-gray-4);
  border-radius: 16px 16px 0 0 !important;
  background: var(--Gray-light);
}
._sheet .head-default {
  background: var(--Gray-light);
  border-radius: 16px 16px 0 0 !important;
}
._sheet .content-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px 16px;
}
._sheet .content-top h4 {
  font-family: var(--roboto-med);
}
._sheet .content-top__transfer {
  text-transform: capitalize;
}`, "",{"version":3,"sources":["webpack://./src/common/ui/bottomSheet/bottomSheet.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;AACF;AAAE;EACE,uBAAA;AAEJ;AADI;EACE,sBAAA;AAGN;AAAE;EACE,uCAAA;AAEJ;AACI;EACE,2CAAA;EACA,uCAAA;EACA,6BAAA;AACN;AACI;EACE,6BAAA;EACA,uCAAA;AACN;AAEE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,oBAAA;AAAJ;AACI;EACE,8BAAA;AACN;AACI;EACE,0BAAA;AACN","sourcesContent":["._sheet {\n  z-index: 999 !important;\n  .react-modal-sheet-header {\n    height: 20px !important;\n    span {\n      width: 24px !important;\n    }\n  }\n  .react-modal-sheet-container  {\n    border-radius: 16px 16px 0 0 !important;\n  }\n  .head{\n    &-scroll {\n      border-bottom: 1px solid var(--Gray-gray-4);\n      border-radius: 16px 16px 0 0 !important;\n      background: var(--Gray-light);\n    }\n    &-default {\n      background: var(--Gray-light);\n      border-radius: 16px 16px 0 0 !important;\n    }\n  }\n  .content-top {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 0 16px 16px;\n    h4 {\n      font-family: var(--roboto-med);\n    }\n    &__transfer {\n      text-transform: capitalize;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
