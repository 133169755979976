import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import CloseModalIcon from "common/icons/closeModalIcon";
import RedDeleteIcon from "common/icons/redDeleteIcon";
import {modalTypes, useModalStore} from "store/modal";
import {EParamsType} from "common/ui/modals/deleteModal/deleteModal";
import {useProjectStore} from "store/projects";
import {useDrawerStore} from "store/drawer";
import '../bottomSheet.scss'

export const BottomSheetContentTop = ({onClose}) => {
  const { t } = useTranslation();
  const {node} = useDrawerStore(state => state);
  const { openModal } = useModalStore((state) => state);
  const { drawerData } = useProjectStore(s => s);

  const title = useMemo(() => {
    if(drawerData?.tx_hash) {
      return t("common.transfer")
    }

    if(drawerData?.entity_name) {
      return drawerData?.entity_name
    }
    return t("common.unknownWallet");
  },[drawerData?.tx_hash,drawerData?.entity_name,t])

  const handleDelete = async () => {
    if(drawerData?.tx_hash) {
      await openModal(modalTypes.deleteProject,
        {network:drawerData.id, editName: t("common.transfer"), type: EParamsType.tx_hash})
    } else {
      const { id, title } = node?.getModel();
      await openModal(modalTypes.deleteProject, {
        network: id,
        editName: title,
        type: EParamsType.rectNode});
    }
  }

	return (<div className="content-top">
		<CloseModalIcon width={19} height={20} onClick={onClose} />
		<h4 className={drawerData?.tx_hash && 'content-top__transfer'}>{title}</h4>
		<RedDeleteIcon width={26} height={25} onClick={handleDelete} />
	</div>);
};
