// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar_list__inner-wrapper .button {
  transition: 0.2s;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
}
.sidebar_list__inner-wrapper .button:not(:disabled) {
  cursor: pointer;
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/common/ui/buttonComponent/button.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,uBAAA;AAAJ;AACI;EACE,eAAA;EACA,UAAA;AACN","sourcesContent":[".sidebar_list__inner-wrapper {\n  .button{\n    transition: 0.2s;\n    border: none;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background: transparent;\n    &:not(:disabled){\n      cursor: pointer;\n      padding: 0;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
