import React, {useCallback, useState} from 'react';
import { Dropdown } from 'antd';
import {modalTypes, useModalStore} from "store/modal";
import {DeleteModal} from "common/ui/modals/deleteModal/deleteModal";
import {useTranslation} from "react-i18next";
import EditProjectDrawer from "common/ui/editProjectDrawer";
import {getDeviceSize} from "common/utils/responsive";
import "./table-action.scss";

export const TableActions = ({item,icon}) => {
  const {key,id, name} = item;
  const networkId= key || id;
  const { t } = useTranslation();
  const { openModal } = useModalStore((state) => state);
  const [open, setOpen] = useState(false);
  const { isMobile} = getDeviceSize();

  const items = [
    {
      label: (<span>{t("initial.editName")}</span>),
      key: modalTypes.editProject,
    },
    {
      label: (<span>{t("initial.deleteProject")}</span>),
      key: modalTypes.deleteProject,
    },
  ];

  /*
  * @key: string = modalTypes
  * */
  const onClick = () => {
    openModal(modalTypes.editProject, {network: networkId, editName: name});
  };

  const showDefaultDrawer = () => {
    setOpen(true);
  };

  const onChange = useCallback(
    async ({ key }) => {
      switch (key) {
        case 'editProject':
          if(isMobile) {
            showDefaultDrawer()
          } else {
            onClick();
          }
          break;
        default:
          openModal(modalTypes.deleteProject, {
            network: networkId,
            editName: name,
          });
      }
    }, [openModal,name,networkId]
  );

	return <div onClick={(e) => {e.stopPropagation()}}>
		<Dropdown
      onClick={(e) => {
        e.stopPropagation();
      }}
			rootClassName="table-action"
			menu={{items, onClick:onChange}}
			trigger={['click']}
		>
      <div onClick={e =>{e.stopPropagation();}}>
        {icon}
      </div>
		</Dropdown>
    {isMobile && <EditProjectDrawer open={open} setOpen={setOpen} name={name} networkId={networkId}/>}
		<DeleteModal />
	</div>
}
