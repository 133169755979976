import React, {useEffect} from "react";
import {useProjectStore} from "store/projects";
import Transfers from "./transfers";
import WalletDetails from "./walletDetails";
import "./details-tab.styles.scss";

export const DetailsTab = ({item, graph}) => {
  const { getWalletOverview, drawerData, getTransferOverview } = useProjectStore(s => s);

  useEffect(() => {
    const model = item?.getModel();
    const type = model?.type;
    const id = model?.id;

    if (type === 'hvh') {
      getTransferOverview({id})
    } else {
      getWalletOverview({id})
    }
  }, [item, getTransferOverview, getWalletOverview]);

  if(drawerData?.tx_hash) {
    return <Transfers drawerData={drawerData} />
  }
  return <WalletDetails graph={graph}/>;
};
