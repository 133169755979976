import React, {useEffect} from "react";
import {DrawerComponent} from "common/ui/drawerComponent/drawerComponent";
import {modalTypes, useModalStore} from "store/modal";
import {useDrawerStore} from "store/drawer";
import {DetailsTab} from "../details-tab";
import {onNodeClickSetActive} from "../../utils/onNodeClickSetActive";
import {nodeCustomEvents} from "../../graph";
import {getDeviceSize} from "common/utils/responsive";
import {BottomSheetComponent} from "common/ui/bottomSheet/bottomSheet";
import {onDragNode} from "../../utils/nodeDragging";
import {zoomCanvas} from "../../utils/zoomCanvas";
import {useProjectStore} from "store/projects";

export const BlockDetails = ({graph}) => {
  const { openModal } = useModalStore((state) => state);
  const {node, setNode} = useDrawerStore(state => state);
  const { resetStateByKey } = useProjectStore(s => s);
  const { isMobile} = getDeviceSize();

  const onDelete = (event) => {
    if (event.target.cfg.name === 'delete-icon') {
      openModal(modalTypes.deleteProject, {network: event.item.getModel().id,
        editName: event.item.getModel().title,type:event.item.getModel().type}
      );
    }
  }

  const openAddressDetails = (event) => {
    if (event.target.cfg.name === 'delete-icon') {
      onDelete(event);
    } else {
      onNodeClickSetActive(graph, event);
      setNode(event.item);
    }
  }

  useEffect(() => {
    if (!!graph) {
      if(isMobile) {
        const canvas = document.getElementsByTagName('canvas')[0];
        const {addEvent, removeEvent} = onDragNode({graph, canvas, openAddressDetails});
        const { initZoom } = zoomCanvas({canvas, graph});

        addEvent();
        initZoom();
        graph.on('edge:touchstart', openAddressDetails);
        return () => {
          resetStateByKey([{
            key: 'objects',
            value: {
              transfers: null,
              wallets: null,
            }
          }]);
          removeEvent();
        };

      }
      graph.on('node:click', openAddressDetails);
      graph.on('edge:click', openAddressDetails);
    }
  }, [graph]);

  const onClose = () => {
    if (graph) {
      graph.setItemState(node, nodeCustomEvents.clicked, false);
      graph.setItemState(node, nodeCustomEvents.edgeActive, false);
      graph.setItemState(node, nodeCustomEvents.edgeHover, false);
      window.history.replaceState({}, "", window.location.pathname);
    }
    setNode(null);
  }

  return isMobile ? (
    <BottomSheetComponent open={node} graph={graph} onClose={onClose}>
      <DetailsTab item={node} />
    </BottomSheetComponent>
  ) : <DrawerComponent graph={graph} open={node} onClose={onClose}>
    <DetailsTab item={node} graph={graph} />
  </DrawerComponent>;
};
