import React, {useMemo} from "react";
import cn from "classnames";
import {debounce} from "lodash";
import {COLUMN_CLASSNAME, EColumns} from "../../../../../../constants";
import Button from "common/ui/buttonComponent/Button";
import IconEyeOpen from "common/icons/IconEyeOpen";
import IconCheck from "common/icons/IconCheck";
import IconPlus from "common/icons/IconPlus";

const ActionButton = ({item,rowHover,onClick}) => {
  const icons = useMemo(() => {
    if (item.id && rowHover) {
      return <IconEyeOpen className='eyeOpen'/>;
    }
    if (item.id) {
      return <IconCheck className={'iconCheck'}/>;
    }
    return <IconPlus className={'iconPlus'} onClick={debounce(() => onClick(item), 300)}/>;
  }, [ rowHover,onClick,item]);

  return (
    <div className={cn(COLUMN_CLASSNAME, `_${EColumns.actions}`)}>
      <Button className={cn({["_active"]: item.id})}>
        {icons}
      </Button>
    </div>
  )
}
export default ActionButton;