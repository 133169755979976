// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.copy-tooltip__overlay .ant-tooltip-inner {
  padding: 12px 8px;
  border-radius: 8px;
}
.copy-tooltip__overlay svg {
  margin-left: auto;
  float: right;
}
.copy-tooltip__overlay__content {
  position: relative;
  color: var(--Gray-gray-9);
  cursor: pointer;
  padding-right: 16px;
}
.copy-tooltip__overlay__text {
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 0;
  width: 160px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-align: start;
}
.copy-tooltip__overlay__icon {
  position: absolute;
  right: 0;
  bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/common/ui/copyTooltip/index.scss"],"names":[],"mappings":"AAEI;EACE,iBAAA;EACA,kBAAA;AADN;AAGI;EACE,iBAAA;EACA,YAAA;AADN;AAGI;EACE,kBAAA;EACA,yBAAA;EACA,eAAA;EACA,mBAAA;AADN;AAGI;EACE,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,YAAA;EACA,gBAAA;EACA,oBAAA;EACA,qBAAA;EACA,4BAAA;EACA,iBAAA;AADN;AAII;EACE,kBAAA;EACA,QAAA;EACA,SAAA;AAFN","sourcesContent":[".copy-tooltip {\n  &__overlay {\n    .ant-tooltip-inner {\n      padding: 12px 8px;\n      border-radius: 8px;\n    }\n    svg {\n      margin-left: auto;\n      float: right;\n    }\n    &__content {\n      position: relative;\n      color: var( --Gray-gray-9);\n      cursor: pointer;\n      padding-right: 16px;\n    }\n    &__text {\n      font-size: 12px;\n      line-height: 16px;\n      margin-bottom: 0;\n      width:160px;\n      overflow: hidden;\n      display: -webkit-box;\n      -webkit-line-clamp: 2;\n      -webkit-box-orient: vertical;\n      text-align: start;\n\n    }\n    &__icon {\n      position: absolute;\n      right: 0;\n      bottom: 0;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
