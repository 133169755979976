import React from "react";
import TableRow from "../tableRow";
import {useTranslation} from "react-i18next";
import {useProjectStore} from "store/projects";
import AssetAmount from "common/ui/assetAmount";
import formatDate, {EFormats} from "common/utils/formatDate";
import {getCategoryTranslations} from "common/utils/categoryTranslation";
import compressText from "common/utils/compressText";
import CopyContent from "common/ui/copyContent";
import './index.scss'

const TableRowGroup = ({ children }) => {
  return <div className={"table-row-group_wrapper"}>{children}</div>;
};
const MainInfo = () => {
  const {t} = useTranslation();
  const { drawerData} = useProjectStore(s => s);
    return (
        <div className={"sidebar_main-info__wrapper"}>
            <div className={"sidebar_main-info__inner-wrapper"}>
                <TableRowGroup>
                    <TableRow title={t("common.address")} value={<CopyContent
                      children={compressText(drawerData?.address,6,4)}
                      copyText={drawerData?.address}/>} />
                    <TableRow title={t("common.category")} value={getCategoryTranslations(drawerData?.entity_category)} />
                    <TableRow title={t("common.balance")} value={<AssetAmount
                      amount={drawerData.balance_usd}
                      asset="USD"
                      disableSign={true}/>} />
                </TableRowGroup>
                <TableRowGroup>
                    <TableRow title={t("common.inflow")} value={
                      <>
                        <AssetAmount
                          amount={drawerData.inflow_usd}
                          asset="USD"
                          disableSign={true}
                          style={{marginRight:'3px'}}
                        />
                        <AssetAmount
                          amount={drawerData.input_count}
                          asset={drawerData.input_count < 2 ? t("common.transfer") : t("common.transfers")}
                          disableSign={true}
                          visible={false}
                          digits={1}
                          style={{ color: '#8C8D94' }}
                          brackets={true}
                        />
                      </>
                    }/>
                    <TableRow title={t("common.outflow")} value={
                      <>
                        <AssetAmount
                          amount={drawerData.outflow_usd}
                          asset="USD"
                          disableSign={true}
                          style={{marginRight:'3px'}}
                        />
                        <AssetAmount
                          amount={drawerData.output_count}
                          asset={drawerData.output_count < 2 ? t("common.transfer") : t("common.transfers")}
                          disableSign={true}
                          visible={false}
                          digits={1}
                          style={{ color: '#8C8D94' }}
                          brackets={true}
                      />
                      </>
                    }/>
                </TableRowGroup>
                <TableRowGroup>
                    <TableRow title={t("initial.addresses")}
                        value={<AssetAmount
                          amount={drawerData.address_count}
                          digits={1}
                          asset={drawerData?.address_count < 2 ? t("common.address") :
                            t("initial.addresses")}
                          visible={false}
                          disableSign={true}/>}
                    />
                  <TableRow title={t("common.transfers")}
                            value={drawerData?.transfer_count < 2 ? `${drawerData?.transfer_count} ${t("common.transfer")}` :
                              <AssetAmount
                                visible={false}
                                amount={drawerData?.transfer_count}
                                digits={1}
                                asset={`${t("common.transfers")}`}
                                disableSign={true}/>}/>
                </TableRowGroup>
                <TableRowGroup>
                    <TableRow
                        title={t("common.firstTX")}
                        value={formatDate(drawerData?.first_transfer_at, EFormats.LT)}
                    />
                    <TableRow
                        title={t("common.lastTx")}
                        value={formatDate(drawerData?.last_transfer_at, EFormats.LT)}
                    />
                </TableRowGroup>
            </div>
        </div>
    );
};

export default MainInfo;

