import React from 'react';
import { Form, Input } from 'antd';
import './styles.scss'
import {useTranslation} from "react-i18next";

const FormEditProject = ({input,setInput}) => {
  const {t} = useTranslation();
  return (
    <Form
      name="trigger"
      style={{ maxWidth: 600 }}
      layout="vertical"
      autoComplete="off">
      <Form.Item
        label={t("initial.name")}
        validateTrigger="onBlur"
        rules={[{ min: 3 }]}
      >
        <Input
          ref={el => {setTimeout(() => el?.focus())}}
          autoFocus
          value={input}
          onChange={(e) => setInput(e.target.value)}
        />
      </Form.Item>
    </Form>
  )
}
export default FormEditProject;