
import i18n from "../../i18n";
export const  CategoryType = {
  atm: "atm",
  bridge: "bridge",
  child_abuse_material: "child_abuse_material",
  custodial_wallet: "custodial_wallet",
  darknet_market: "darknet_market",
  dex: "dex",
  enforcement_action: "enforcement_action",
  exchange: "exchange",
  fraud_shop: "fraud_shop",
  gambling: "gambling",
  high_risk_exchange: "high_risk_exchange",
  high_risk_jurisdiction: "high_risk_jurisdiction",
  iaas: "iaas",
  ico: "ico",
  illegal_service: "illegal_service",
  lending: "lending",
  marketplace: "marketplace",
  mining: "mining",
  mining_pool: "mining_pool",
  mixer: "mixer",
  nft_marketplace: "nft_marketplace",
  online_pharmacy: "online_pharmacy",
  other: "other",
  p2p_exchange: "p2p_exchange",
  payment_service_provider: "payment_service_provider",
  personal_wallet: "personal_wallet",
  privacy_protocol: "privacy_protocol",
  ransomware: "ransomware",
  sanctions: "sanctions",
  scam: "scam",
  seized_funds: "seized_funds",
  smart_contract: "smart_contract",
  stolen_funds: "stolen_funds",
  terrorist_financing: "terrorist_financing",
  token_contract: "token_contract",
  unnamed_wallet: "unnamed_wallet",
  null:"null"
};

export const CategoryTranslations = () => ({
  [CategoryType.atm]: i18n.t("category.atm"),
  [CategoryType.bridge]: i18n.t("category.bridge"),
  [CategoryType.child_abuse_material]: i18n.t("category.child_abuse_material"),
  [CategoryType.custodial_wallet]: i18n.t("category.custodial_wallet"),
  [CategoryType.darknet_market]: i18n.t("category.darknet_market"),
  [CategoryType.dex]: i18n.t("category.dex"),
  [CategoryType.enforcement_action]: i18n.t("category.enforcement_action"),
  [CategoryType.exchange]: i18n.t("category.exchange"),
  [CategoryType.fraud_shop]: i18n.t("category.fraud_shop"),
  [CategoryType.gambling]: i18n.t("category.gambling"),
  [CategoryType.high_risk_exchange]: i18n.t("category.high_risk_exchange"),
  [CategoryType.high_risk_jurisdiction]: i18n.t("category.high_risk_jurisdiction"),
  [CategoryType.iaas]: i18n.t("category.iaas"),
  [CategoryType.ico]: i18n.t("category.ico"),
  [CategoryType.illegal_service]: i18n.t("category.illegal_service"),
  [CategoryType.lending]: i18n.t("category.lending"),
  [CategoryType.marketplace]: i18n.t("category.marketplace"),
  [CategoryType.mining]: i18n.t("category.mining"),
  [CategoryType.mining_pool]: i18n.t("category.mining_pool"),
  [CategoryType.mixer]: i18n.t("category.mixer"),
  [CategoryType.nft_marketplace]: i18n.t("category.nft_marketplace"),
  [CategoryType.online_pharmacy]: i18n.t("category.online_pharmacy"),
  [CategoryType.other]: i18n.t("category.other"),
  [CategoryType.p2p_exchange]: i18n.t("category.p2p_exchange"),
  [CategoryType.payment_service_provider]: i18n.t("category.payment_service_provider"),
  [CategoryType.personal_wallet]: i18n.t("category.personal_wallet"),
  [CategoryType.privacy_protocol]: i18n.t("category.privacy_protocol"),
  [CategoryType.ransomware]: i18n.t("category.ransomware"),
  [CategoryType.sanctions]: i18n.t("category.sanctions"),
  [CategoryType.scam]: i18n.t("category.scam"),
  [CategoryType.seized_funds]: i18n.t("category.seized_funds"),
  [CategoryType.smart_contract]: i18n.t("category.smart_contract"),
  [CategoryType.stolen_funds]: i18n.t("category.stolen_funds"),
  [CategoryType.terrorist_financing]: i18n.t("category.terrorist_financing"),
  [CategoryType.token_contract]: i18n.t("category.token_contract"),
  [CategoryType.unnamed_wallet]: i18n.t("category.unnamed_wallet"),
  [CategoryType.null]: i18n.t("common.unknownWallet")
});

export const getCategoryTranslations = (val) => {
  if (!val) return CategoryTranslations()[CategoryType.null];
  return CategoryTranslations()[val];
};

