import {nodeCustomEvents} from "../graph";

/**
 * graph: G6 graph element
 * event
 * */
export const onNodeClickSetActive = (graph, event) => {
  const clickedNodes = graph.findAllByState('node', nodeCustomEvents.clicked);
  const clickedEdges = graph.findAllByState('edge', nodeCustomEvents.edgeActive);

  for (const node of clickedEdges) {
    graph.setItemState(node, nodeCustomEvents.edgeActive, false)
  }

  for (let nodeItem of clickedNodes) {
    graph.setItemState(nodeItem, nodeCustomEvents.clicked, false);
  }

  graph.setItemState(event.item, nodeCustomEvents.clicked, true);
  graph.setItemState(event.item, nodeCustomEvents.edgeActive, true);
}

// TODO for active edge
// export const onNodeClickSetActive = (graph, event) => {
//   const { nodes } = graph.save();
//   const clickedNodes = graph.findAllByState('node', nodeCustomEvents.clicked);
//   const type = event.item.get('type');
//
//   if (nodes.length) {
//     for (const node of nodes) {
//       graph.findById(node.id)
//         ?.getEdges()
//         ?.forEach((edge) => graph.setItemState(edge, nodeCustomEvents.edgeRunning, false));
//     }
//   }
//
//   for (let nodeItem of clickedNodes) {
//     graph.setItemState(nodeItem, nodeCustomEvents.clicked, false);
//   }
//
//   if (type === 'node') {
//     const edges = event.item.getEdges();
//     edges.forEach((edge) => graph.setItemState(edge, nodeCustomEvents.edgeRunning, true));
//   }
//   graph.setItemState(event.item, nodeCustomEvents.clicked, true);
// }

