import React, {useEffect} from "react";
import Filter from "../filter";
import {useProjectStore} from "store/projects";
import {useDrawerStore} from "store/drawer";
import {useFetchTransfersHook} from "hooks/useFetchTransferHook";
import Cols from "./components/Cols";
import VirtualList from "./list";

const List = ({graph}) => {
  const {node} = useDrawerStore(s => s);
  const { clearWalletTransfers } = useProjectStore(state => state);
  const { fetchTransfers, loading,setLoading } = useFetchTransfersHook();

  useEffect(() => {
    const type = node?.get('type');
    const id = node?.get('id');

    if (type === 'node') {
      fetchTransfers(id);
    }

    return () => {
      clearWalletTransfers();
    }
  }, [node]);

  return (
    <div className={"sidebar_list__wrapper"}>
      <div className={"sidebar_list__inner-wrapper"}>
        <div className={"list_head__wrapper"}>
          <Filter/>
          <Cols />
        </div>
        <VirtualList graph={graph} loading={loading} setLoading={setLoading}/>
      </div>
    </div>
  );
};

export default List;
