const IconEyeOpen = (props) => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M7.73437 10C7.73437 10.5802 7.96483 11.1366 8.37507 11.5468C8.78531 11.957 9.3417 12.1875 9.92187 12.1875C10.502 12.1875 11.0584 11.957 11.4687 11.5468C11.8789 11.1366 12.1094 10.5802 12.1094 10C12.1094 9.41984 11.8789 8.86344 11.4687 8.4532C11.0584 8.04297 10.502 7.8125 9.92187 7.8125C9.3417 7.8125 8.78531 8.04297 8.37507 8.4532C7.96483 8.86344 7.73437 9.41984 7.73437 10ZM18.4023 9.49609C16.5508 5.5957 13.7519 3.63281 9.99999 3.63281C6.24608 3.63281 3.44921 5.5957 1.59765 9.49805C1.52338 9.65531 1.48486 9.82706 1.48486 10.001C1.48486 10.1749 1.52338 10.3466 1.59765 10.5039C3.44921 14.4043 6.24804 16.3672 9.99999 16.3672C13.7539 16.3672 16.5508 14.4043 18.4023 10.502C18.5527 10.1855 18.5527 9.81836 18.4023 9.49609ZM9.92187 13.4375C8.02343 13.4375 6.48437 11.8984 6.48437 10C6.48437 8.10156 8.02343 6.5625 9.92187 6.5625C11.8203 6.5625 13.3594 8.10156 13.3594 10C13.3594 11.8984 11.8203 13.4375 9.92187 13.4375Z"
                fill="#B9BBC2"
            />
        </svg>
    );
};

export default IconEyeOpen;
