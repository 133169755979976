import {nodeCustomEvents} from "../graph";

export function setNodeActiveOnTableHover({graph,node}){
  const changeState = (record, value) => {
		if (!record?.id) return;
		const transfer = graph?.findById(record?.id);
    const selectedId = node?.getModel().id
    const hoveredId = transfer?.getModel().id
    const typeNode = transfer?.getType() === 'node'

    if(hoveredId === selectedId) return;

		if (transfer) {
			graph.setItemState(transfer,  typeNode ? nodeCustomEvents.clicked : nodeCustomEvents.edgeHover, value);
		}
	}

	return {
		onMouseLeave: (wallet) => changeState(wallet, false),
		onMouseEnter: (wallet) => changeState(wallet, true),
	}
}
