import {useEffect, useMemo, useState} from "react";
import {debounce} from "lodash";

export const useZoomInOut = ({graph}) => {
  const zoomLevels = [0.12, 0.25, 0.5, 0.75, 1.0, 1.2, 1.5, 2.0, 2.5, 3.0, 4.0];
  const center = {x: window.innerWidth / 2, y: window.innerHeight / 2};
  const [value, setValue] = useState(1);

  useEffect(() => {
    if (graph) {
      graph.on('wheel', debounce(() => {
        setValue(graph?.getZoom());
      }, 100));
    }
  }, [graph]);

  function changeZoom(isZoomIn) {
    const zoomIndex = setZoomIndex(value);
    let index = isZoomIn ?  zoomIndex + 1 : zoomIndex - 1;

    if (index >= zoomLevels.length) {
      index = zoomLevels.length - 1;
    }

    if (index <= 0) {
      index = 0;
    }

    graph?.zoomTo(zoomLevels[index], center, true);
    setValue(zoomLevels[index]);
  }

  const setZoomIndex = () => {
    function getClosestIndex(a, b, value) {
      const left = Math.abs(value - zoomLevels[a]);
      const right = Math.abs(zoomLevels[b] - value);
      return left < right ? a : b;
    }

    for (let a = 0, b = 1; a < zoomLevels.length ; a++, b++) {
      if (value <= zoomLevels[b]) {
        return getClosestIndex(a, b, value);
      }
    }

    return 4;
  };

  const fullscreen = () => {
    graph.fitView([100,48,48,48], null, true);

    setTimeout(() => {
      setValue(graph?.getZoom());
    }, 400);
  }

  const fitCenter = (item) => {
    if (!item) return;
    setValue(1.5);
    graph?.zoomTo(1.5);
    graph?.focusItem(item, false);
  };

  const zoomValue = useMemo(() => Math.floor(value * 100), [value]);

  return {
    zoomIn: () => changeZoom(true),
    zoomOut: () => changeZoom(false),
    fullscreen,
    fitCenter,
    zoomValue,
  }
}
