import {useEffect} from "react";
import {useParams} from "react-router-dom";
import {useProjectStore} from "store/projects";
import InitialGraph from "./InitialGraph";
import Spinner from "common/ui/spinner";

export default function () {
	const { projectId } = useParams();
	const {objects, getObjects, resetStateByKey} = useProjectStore(s => s);

	useEffect(() => {
		if (!!projectId) {
			getObjects({id: projectId});
		}
		return () => {
			resetStateByKey([{
				key: 'objects',
				value: {
					transfers: null,
					wallets: null,
				}
			}]);
		}
	}, [projectId]);

	if (!objects?.wallets) {
		return <Spinner />
	}

	return <InitialGraph objects={objects} />
}
