import cn from "classnames";

const TableRow = ({ title, value, isDarkTitle }) => {
  return (
    <div
      className={cn("table-row_wrapper", {
        [`_dark-title`]: isDarkTitle,
      })}
    >
      <span className={"table-row_title"}>{title}:</span>
      <span className={"table-row_value"}>{value ?? "—"}</span>
    </div>
  );
};
export default TableRow;