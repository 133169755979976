import React, {useEffect, useState} from "react"
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {debounce} from "lodash";

import ArrowRight from "common/icons/arrowRight";
import Plus from "common/icons/plus";
import compressText from "common/utils/compressText";
import AssetAmount from "common/ui/assetAmount";
import Spinner, {ESpinnerType} from "common/ui/spinner";
import {useProjectStore} from "store/projects";
import {useDrawerStore} from "store/drawer";
import {getNodes} from "pages/Graph/utils";
import {useAddNewTransfer} from "pages/Graph/utils/addTransfer";
import {setNodeActiveOnTableHover} from "pages/Graph/utils/setNodeActiveOnTableHover";
import "../search-panel.scss";

/*
* @type: string - searchItemType;
* @account: [];
* @transfers: [];
* */
export const SearchItem = ({wallets, transfers, fetching, searchValue, graph}) => {
  const { addWallet, walletTransferLoader, objects } = useProjectStore(s => s);
  const {node} = useDrawerStore(s => s);
  const { projectId } = useParams();
  const { postNewTransfer } = useAddNewTransfer({graph, projectId});
  const [newWallet, setWallet] = useState();
  const { onMouseLeave, onMouseEnter } = setNodeActiveOnTableHover({graph,node});
  const {t} = useTranslation();

  useEffect(() => {
    if (newWallet) {
      const nodes = getNodes(objects?.transfers, objects.wallets);
      for (const node of nodes) {
        if (node.id === newWallet.id) {
          graph.addItem('node', node);
        }
      }
      setWallet(null);
    }
  }, [newWallet]);

  const addItem = async (ext_id, isWallet = false) => {
    if (isWallet) {
      const body = {id: projectId, ext_id, cb: (wallet) => setWallet(wallet)};
      await addWallet(body);
    } else {
      // to create new transfer
      await postNewTransfer(ext_id);
    }
  };
  if (!searchValue?.length) {
    return null;
  }
  const ItemCommon = ({children, wallet}) => (
    <div className='item'
         onMouseEnter={debounce(() => onMouseEnter(wallet), 100)}
         onMouseLeave={debounce(() => onMouseLeave(wallet), 100)}>{children}</div>
  )

  if(fetching) {
   return <ItemCommon>
      <Spinner iconType={ESpinnerType.loading}/>
    </ItemCommon>

  }

	if (!wallets?.length && !transfers?.length) {
    return <ItemCommon>
      <span className='no-data'>{t("common.noData")}</span>
    </ItemCommon>
	}
  const WrapperItems = ({children,title}) => {
   return <div className='search-items-block'>
       <div className='search-items-block__header'>
         <span className='dot'></span>
         <h4>{title}</h4>
       </div>
       {children}
     </div>
  }
  return <>
      {!!wallets?.length &&  <WrapperItems title={t("initial.addresses")}>
        {wallets.map(account => (
          <ItemCommon wallet={account} key={account.id}>
            <p className='item__data-address'>{compressText(account.address ,37)}</p>
            {walletTransferLoader ? <Spinner iconType={ESpinnerType.loading} className='spinner' size='small'/>
              : (account.id  ? <span className='select'></span> : <Plus className="add" onClick={(() => addItem(account.ext_id, true))}/>)}
          </ItemCommon>))}
      </WrapperItems>}
      {!!transfers?.length &&  <WrapperItems title={t("common.transfers")}>
        {transfers.map(trf => (
          <ItemCommon wallet={trf} key={trf.ext_id}>
            <div className='search-transfer'>
              <p>{compressText(trf.sender_address, 6, 4)}</p>
              <ArrowRight/>
              <p>{compressText(trf.recipient_address, 6, 4)}</p>
            </div>
            <div>
              <b>
                <AssetAmount
                  amount={trf.amount}
                  asset={trf.token_symbol || null}
                  disableSign={true}
                />
              </b>
              {walletTransferLoader && <Spinner iconType={ESpinnerType.loading} className='spinner' size='small'/>}
              {!walletTransferLoader && (trf.id ? <span className='select'></span> :
                <Plus className="add" onClick={(() => addItem(trf.ext_id))}/>)}
            </div>
          </ItemCommon>))}
      </WrapperItems>}
    </>
};
