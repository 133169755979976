import React from "react";
import {useParams} from "react-router-dom";

import Spinner from "common/ui/spinner";
import {useProjectStore} from "store/projects";
import {setNodeActiveOnTableHover} from "pages/Graph/utils/setNodeActiveOnTableHover";
import {useAddNewTransfer} from "pages/Graph/utils/addTransfer";
import Item from "./components/item";

const VirtualList = ({graph, loading,setLoading}) => {
  const { projectId } = useParams();
  const { postNewTransfer } = useAddNewTransfer({graph, projectId});
	const {walletTransfers,updateWalletTransfers } = useProjectStore(state => state);
	const { onMouseLeave, onMouseEnter } = setNodeActiveOnTableHover({graph});

	const onClick = (item) => {
		const key = item?.ext_id;
		if (key && !item.id) {
		  setLoading(true);
		  postNewTransfer(key)
		    .then(transfer => {
		      updateWalletTransfers({...item, id: transfer.id});
		    })
		    .finally(() => {
		      setLoading(false);
		    });
		}
	}

	return (
		<div className={"list_body__wrapper"}>
			{(walletTransfers?.transfers || [])?.map(item => <Item
				key={item.id || item.ext_id}
				{...item}
				item={item}
				onClick={onClick}
				onMouseLeave={onMouseLeave}
				onMouseEnter={onMouseEnter}
			/>)}
			{(!walletTransfers || walletTransfers?.count >= 20 || loading) && <div className="loading">
				<Spinner/>
			</div>}
		</div>
	);
};

export default VirtualList;
