import {useState} from "react";
import {useProjectStore} from "store/projects";
import {debounce} from "lodash";

export function useFetchTransfersHook() {
	const {updateWalletTransfers, getWalletTransfer, clearWalletTransfers } = useProjectStore(state => state);
	const [loading, setLoading] = useState(false);

	const fetchTransfers = debounce((id) => {
		if (id) {
			setLoading(true);
			getWalletTransfer({
				id,
				params: { limit: 20 }
			}).then(() => {
				setLoading(false);
			});
		}
	}, 200);

	return {
		fetchTransfers,
		loading,
		clearWalletTransfers,
		updateWalletTransfers,
    setLoading
	}
}
