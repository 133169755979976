import cn from "classnames";
import {useTranslation} from "react-i18next";

import {COLUMN_CLASSNAME, EColumns} from "../../../../constants";

const Cols = () => {
	const {t} = useTranslation();

	return (
		<div className={"list_head__inner-wrapper"}>
			<div
				className={cn(
					COLUMN_CLASSNAME,
					`_${EColumns.hash}`,
				)}
			>
				{t("common.hashDate")}
			</div>
			<div
				className={cn(
					COLUMN_CLASSNAME,
					`_${EColumns.value}`,
				)}
			>
				{t("common.value")}
			</div>
			<div
				className={cn(
					COLUMN_CLASSNAME,
					`_${EColumns.counterparty}`,
				)}
			>
				{t("common.counterparty")}
			</div>
			<div
				className={cn(
					COLUMN_CLASSNAME,
					`_${EColumns.actions}`,
				)}
			/>
		</div>
	);
};

export default Cols;
