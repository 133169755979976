import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Input} from 'antd';
import {modalTypes, useModalStore} from "store/modal";
import {useProjectStore} from "store/projects";
import ModalContainer from "../modalContainer";
import ButtonComponent from "../../button/button";
import CloseModalIcon from "common/icons/closeModalIcon";
import "./styles.scss";

const { TextArea } = Input;

const CreateEditProjectModal = () => {
  const { editProject } = useProjectStore((state) => state);
  const { modalType, closeModal, params } = useModalStore((state) => state);
  const [input, setInput] = useState('');
  const { t } = useTranslation();
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    setInput(params?.editName?.name || params?.editName);
  }, [params]);

  const onClose = () => {
    setInput('');
    closeModal();
    setFetching(false);
  };

  const onSave = async () => {
    if (!input || !input.length || fetching) return;
    setFetching(true);
    const eventParams = { id: params.network, name: input };

    if (!!params.editName) {
      await editProject(eventParams, onClose);
    }
  };

  return (
    <ModalContainer closeModal={closeModal} open={modalType === modalTypes.createProject || modalType === modalTypes.editProject}>
      <div className="modal-wrapper">
        <div className="modal-header">
          <p>{params?.editName ? t("initial.editName") : t("initial.createProject")}</p>
          <span className="icon" onClick={() => closeModal()}><CloseModalIcon/></span>
        </div>
        <div className="modal-content">
          <span className="label">{t("initial.name")}</span>
          <TextArea
            placeholder={t("initial.name")}
            autoSize
            value={input}
            onChange={e => setInput(e.target.value)}
          />
          <ButtonComponent
            onClick={onSave}
            loading={fetching}
            colorType="primary"
            text={t("initial.save")} />
        </div>
      </div>
    </ModalContainer>
  );
};
export default CreateEditProjectModal;
