import Button from "common/ui/buttonComponent/Button";
import { FC, PropsWithChildren, useState } from "react";
import { Flex, Tag } from "antd";
import { TagProps } from "antd/es/tag";
import {useTranslation} from "react-i18next";
import IconFilter from "common/icons/IconFilter";
import IconCrossSharp from "common/icons/IconCrossSharp";

const Filter = () => {
  const [isActive, setIsActive] = useState(true);
  const {t} = useTranslation();

  return (
    <div className={"filter_wrapper"}>
      <div className={"filter_title__wrapper"}>
        <Flex align="center" gap={8}>
          <h3>{t("common.transfers")}</h3>
          <Button
            onClick={() => setIsActive((prevState) => !prevState)}
          >
            <IconFilter isActive={isActive} />
          </Button>
        </Flex>
        {/*TODO with API*/}
        {/*<Button*/}
        {/*  onClick={() => setIsActive((prevState) => !prevState)}*/}
        {/*  className={"_sort"}*/}
        {/*>*/}
        {/*  by date <IconArrowDouble isActive={isActive} />*/}
        {/*</Button>*/}
      </div>
      {/*TODO with API*/}
      {/*<div className={"filter_tags__wrapper"}>*/}
      {/*  <TagItem>short</TagItem>*/}
      {/*  <TagItem>09.03.2023 — 01.02.2024</TagItem>*/}
      {/*  <TagItem>*/}
      {/*    Длинный тест ДлинныйтестДлинныйтест Длинный тест Длинный*/}
      {/*    тест Длинный тест Длинный тест 1231 2312 312 3Длинный тест*/}
      {/*    ДлинныйтестДлинныйтест Длинный тест Длинный тест Длинный*/}
      {/*    тест Длинный тест 1231 2312 312 3Длинный тест*/}
      {/*    ДлинныйтестДлинныйтест Длинный тест Длинный тест Длинный*/}
      {/*    тест Длинный тест 1231 2312 312 3Длинный тест*/}
      {/*    ДлинныйтестДлинныйтест Длинный тест Длинный тест Длинный*/}
      {/*    тест Длинный тест 1231 2312 312 3*/}
      {/*  </TagItem>*/}
      {/*</div>*/}
    </div>
  );
};

export default Filter;

const TagItem = ({children, onClose, ...rest}) => {
  return (
    <Tag
      closeIcon={
        <span onClick={onClose}>
                    <IconCrossSharp/>
                </span>
      }
      {...rest}
    >
      <span className="content">{children}</span>
    </Tag>
  );
};
