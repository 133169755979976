export function defaultMovingCanvas({graph, canvasPosition, updateCanvasPosition, projectId}) {
	const {x, y} = canvasPosition;
	const width = window.innerWidth;
	const height = window.innerHeight;
	const middleWidth = width / 2;
	const middleHeight = height / 2;

	try {
		const newX = x === 0 || Math.abs(x) > width ? middleWidth : x;
		const newY = Math.abs(y) > height || y === 0 ? middleHeight : y;

		graph.moveTo(newX, newY, true);
		updateCanvasPosition(projectId, {x: newX, y: newY});
	} catch (e) {
		console.log('DefaultMovingCanvas', e);
	}
}
