import React, {useCallback} from "react";
import cn from "classnames";
import {debounce} from "lodash";

const ItemWrapper = ({children,onMouseEnter,onMouseLeave,item,handleHover,rowHover}) => {
  const handleHoverChange = useCallback((isHovering) => {
    handleHover(isHovering);
    isHovering ? onMouseEnter(item) : onMouseLeave(item);
  }, [item, onMouseEnter, onMouseLeave]);

  return (
    <div className={cn("item_wrapper", {_active: item.is_incoming})}>
      <div
        className={cn("item_inner-wrapper", {_hover: rowHover})}
        onMouseEnter={debounce(() => handleHoverChange(true), 100)}
        onMouseLeave={debounce(() => handleHoverChange(false), 100)}
      >
        {children}
      </div>
    </div>
  )
}
export default ItemWrapper;