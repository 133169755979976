import * as React from "react"
const BackButton = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}>
    <path
      d="M12.0484 1.80103V0.169222C12.0484 0.0277846 11.8859 -0.0503227 11.7761 0.0362286L2.25968 7.46908C2.17883 7.53196 2.1134 7.61248 2.0684 7.70449C2.02339 7.7965 2 7.89757 2 8C2 8.10243 2.02339 8.2035 2.0684 8.29551C2.1134 8.38752 2.17883 8.46804 2.25968 8.53092L11.7761 15.9638C11.888 16.0503 12.0484 15.9722 12.0484 15.8308V14.199C12.0484 14.0955 11.9999 13.9963 11.9197 13.933L4.32003 8.00106L11.9197 2.06702C11.9999 2.00369 12.0484 1.90447 12.0484 1.80103Z"
      fill="#232325"/>
  </svg>
)
export default BackButton
