// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.default-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 25px !important;
  height: 25px;
}

.custom-spinner {
  position: absolute;
  top: 5px;
  right: 6px;
}
.custom-spinner svg {
  position: unset;
}
@media (max-width: 767px) {
  .custom-spinner {
    right: 22px;
  }
}`, "",{"version":3,"sources":["webpack://./src/common/ui/spinner/styles.scss","webpack://./src/common/styles/_mixins.scss"],"names":[],"mappings":"AACA;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,sBAAA;EACA,YAAA;AAAF;;AAGA;EACE,kBAAA;EACA,QAAA;EACA,UAAA;AAAF;AACE;EACE,eAAA;AACJ;ACHE;EDHF;IAQI,WAAA;EAEF;AACF","sourcesContent":["@import \"src/common/styles/mixins\";\n.default-spinner {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  width: 25px !important;\n  height: 25px;\n}\n\n.custom-spinner {\n  position: absolute;\n  top: 5px;\n  right: 6px;\n  svg {\n    position: unset;\n  }\n  @include mobile() {\n    right: 22px;\n  }\n}\n","@mixin font($size: 14px, $weight: 400, $lineHeight: 18px) {\n  font-size: $size;\n  line-height: $lineHeight;\n  font-weight: $weight;\n  font-family: 'Roboto';\n}\n\n@mixin maxHeight() {\n  height: calc(100vh - 7px);\n}\n\n@mixin mobile {\n  @media (max-width: 767px) {\n    @content;\n  }\n}\n\n@mixin tablet {\n  @media (max-width: 1224px) {\n    @content;\n  }\n}\n\n@mixin laptop {\n  @media (max-width: 1440px) {\n    @content;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
