import React,{ useMemo } from "react";
import {formatAssetAmount} from "common/utils/formatAssetAmount";
import eToNumber from "common/utils/eToNumber";
import Hint from "../hint";

const AssetAmount = ({amount, asset, sign, disableSign, className, align, isIncoming, valueUsd, visible, digits,brackets,style}) => {
  const assetAmount = useMemo(() =>
      formatAssetAmount({ amount, asset, sign, disableSign,isIncoming,valueUsd, digits }),
    [amount, asset, sign, disableSign,isIncoming,valueUsd]);

  return (
    <Hint
      content={<span>{eToNumber(amount || 0)}{'\u00A0'}{asset}</span>}
      trigger="hover"
      visible={visible}
      align={align}
      style={style}
    >
      <div className={className}>
        {brackets && <span>{"("}</span>}
        {assetAmount}
        {brackets && <span>{")"}</span>}
      </div>
    </Hint>
  );
};

export default AssetAmount;
