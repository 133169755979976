// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:where(.css-dev-only-do-not-override-1x0dypw).ant-layout {
  background: none;
}

.graph-container {
  height: calc(100vh - 7px);
}`, "",{"version":3,"sources":["webpack://./src/pages/Graph/creation.styles.scss","webpack://./src/common/styles/_mixins.scss"],"names":[],"mappings":"AAEA;EACE,gBAAA;AADF;;AAGA;ECGE,yBAAA;ADFF","sourcesContent":["@import \"../../common/styles/mixins\";\n\n:where(.css-dev-only-do-not-override-1x0dypw).ant-layout {\n  background: none;\n}\n.graph-container {\n  @include maxHeight();\n}\n","@mixin font($size: 14px, $weight: 400, $lineHeight: 18px) {\n  font-size: $size;\n  line-height: $lineHeight;\n  font-weight: $weight;\n  font-family: 'Roboto';\n}\n\n@mixin maxHeight() {\n  height: calc(100vh - 7px);\n}\n\n@mixin mobile {\n  @media (max-width: 767px) {\n    @content;\n  }\n}\n\n@mixin tablet {\n  @media (max-width: 1224px) {\n    @content;\n  }\n}\n\n@mixin laptop {\n  @media (max-width: 1440px) {\n    @content;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
