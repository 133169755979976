import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import BackButton from "../../icons/backButton";
import ButtonComponent from "../button/button";
import {useProjectStore} from "store/projects";
import AntdDrawer from "../antdDrawer";
import FormEditProject from "../formEditProject";

const EditProjectDrawer = ({setOpen, open, name, networkId}) => {
  const {t} = useTranslation();
  const { editProject } = useProjectStore((state) => state);
  const [input, setInput] = useState();
  const [loading, setLoading] = useState(false);
  const { projectId } = useParams();

  const onClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    setInput(name);
  }, [open]);

  const handleClick = () => {
    if (input?.trim() && !loading) {
      setLoading(true);
      editProject({ id: projectId || networkId, name: input}, onClose)
        .finally(() => setLoading(false));
    }
  };

  return (
    <AntdDrawer
      closeIcon={<BackButton/>}
      title={t("initial.editName")}
      placement="right"
      onClose={onClose}
      open={open}
    >
      <FormEditProject input={input} setInput={setInput} />
      <ButtonComponent
        disabled={!input?.trim()?.length}
        colorType='primary'
        onClick={handleClick}
        loading={loading}
        text={!loading && t("initial.save")}/>
    </AntdDrawer>
  )
}
export default EditProjectDrawer;