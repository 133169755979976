// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hint-overlay {
  padding-bottom: 0;
  border-radius: 4px;
}
.hint-overlay .ant-popover-inner {
  background: var(--Gray-gray-3);
  border-radius: 4px;
  padding: 0;
  box-shadow: 0 2px 4px -4px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.04);
}
.hint-overlay .ant-popover-inner-content {
  max-width: 192px;
  max-height: 40px;
  overflow: hidden;
  padding: 4px 8px;
  font-size: 11px;
  line-height: 16px;
  overflow-wrap: break-word;
}
.hint-overlay .ant-popover-arrow {
  display: none;
}
.hint-content {
  align-self: flex-start;
  height: fit-content !important;
}
.hint-content div {
  height: fit-content !important;
}
.hint-content div span:first-child {
  margin-right: -4px;
}`, "",{"version":3,"sources":["webpack://./src/common/ui/hint/styles.scss"],"names":[],"mappings":"AACE;EACE,iBAAA;EACA,kBAAA;AAAJ;AACI;EACE,8BAAA;EACA,kBAAA;EACA,UAAA;EACA,8EACE;AAAR;AAGI;EACE,gBAAA;EACA,gBAAA;EACA,gBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,yBAAA;AADN;AAGI;EACE,aAAA;AADN;AAIE;EACE,sBAAA;EACA,8BAAA;AAFJ;AAGI;EACE,8BAAA;AADN;AAEM;EACE,kBAAA;AAAR","sourcesContent":[".hint {\n  &-overlay {\n    padding-bottom: 0;\n    border-radius: 4px;\n    .ant-popover-inner {\n      background: var( --Gray-gray-3);\n      border-radius: 4px;\n      padding: 0;\n      box-shadow:\n        0 2px 4px -4px rgba(0, 0, 0, 0.1),\n        0px 2px 4px rgba(0, 0, 0, 0.04),\n    }\n    .ant-popover-inner-content {\n      max-width: 192px;\n      max-height: 40px;\n      overflow: hidden;\n      padding: 4px 8px;\n      font-size: 11px;\n      line-height: 16px;\n      overflow-wrap: break-word;\n    }\n    .ant-popover-arrow {\n      display: none;\n    }\n  }\n  &-content {\n    align-self: flex-start;\n    height: fit-content !important;\n    div {\n      height: fit-content !important;\n      span:first-child {\n        margin-right: -4px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
