import React from "react";
import {Drawer} from "antd";
import './styles.scss';

const AntdDrawer = (props) => {
  const { children } = props;
  return (
    <Drawer {...props}>
      {children}
    </Drawer>
  )
}
export default AntdDrawer;