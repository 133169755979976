import React, {useEffect, useMemo} from "react";
import {Dropdown} from "antd";
import {useTranslation} from "react-i18next";
import ButtonComponent from "common/ui/button/button";
import Plus from "common/icons/plus";
import {useNetworkStore} from "store/networks";
import {useProjectStore} from "store/projects";
import "./empty-block.scss";

export const EmptyBlock = () => {
	const {networks, getNetworks} = useNetworkStore((state) => state);
	const { createProject } = useProjectStore((state) => state);
  const { t } = useTranslation();

	useEffect( () => {
		getNetworks();
	}, []);

	const onClick = async ({key}) => {
		await createProject({ id: key });
	}

	const items = useMemo(() => {
		return networks.map(item => ({
			label: `${item.name} (${item.code})`,
			key: item.id
		}))
	}, [networks]);

	return <div className="block">
    <div className='block__container'>
      <p>{t("initial.canCreateFirstProject")}</p>
      <Dropdown
        menu={{items, onClick}}
        paddingBlock={20}
        trigger={['click']}
        placement="bottom">
        <ButtonComponent
          text={t("initial.createProject")}
          colorType="pink"
          icon={<Plus/>}
        />
      </Dropdown>
    </div>
  </div>
}
