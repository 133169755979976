import React, {useState} from "react";
import {useDrawerStore} from "store/drawer";
import { useZoomInOut } from "common/utils/useZoomInOut";
import DollarSign from "../../icons/dollarSign";
import PlusToolbar from "../../icons/plusToolbar";
import MinusToolbar from "../../icons/minusToolbar";
import FullScreenIcon from "../../icons/fullScreenIcon";
import FitCenterIcon from "../../icons/fitCenterIcon";
import "./toolbar.styles.scss";

export const Toolbar = ({open, graph }) => {
  const [isActive, setIsActive] = useState(false);
	const { zoomIn, zoomOut, fullscreen, fitCenter, zoomValue } = useZoomInOut({graph})
  const { node } = useDrawerStore(s => s);

  const handleChange = () => {
    setIsActive(!isActive);
  }

	return <div className={`toolbar toolbar-${open ? 'open' : 'closed'}`}>
    <ul>
      <div className={`equivalent-wrapper ${isActive ? 'active' : ''}`}>
        <li onClick={handleChange}>
         <DollarSign/>
        </li>
      </div>
      <div className="zoom-wrapper">
        <li onClick={zoomIn}>
          <PlusToolbar/>
        </li>
        <li className="zoom-number">{zoomValue}</li>
        <li onClick={zoomOut}>
         <MinusToolbar/>
        </li>
      </div>
      <div className="view-wrapper">
        <li onClick={fullscreen}>
          <FullScreenIcon/>
        </li>
        <li onClick={() => fitCenter(node)}>
         <FitCenterIcon/>
        </li>
      </div>
    </ul>
  </div>
}
