import {colors, nodeCustomEvents} from "../graph";
import {setNodeBackground} from "./setNodeBackground";
import { mix_hexes} from "./fillColor";

export const OnHoverClickEvent = ({name, value, item}) => {
  try {
    const group = item.getContainer();
    const children = group.get('children');
    const node = children[0];
    const deleteImage = children[3];
    const isClicked = item.hasState(nodeCustomEvents.clicked);
    // hide show deleteImage
    deleteImage.cfg.visible = value && !isClicked;
    const model = item.getModel();

    const shadowAttr = (node,name) => {
      if(name) {
        node.attr('stroke', mix_hexes(setNodeBackground(model.entity_category).stroke, "#56594c"));
        node.attr('lineWidth', 1.5);
      }

      if(!isClicked) {
        node.attr('shadowBlur', 10);
        node.attr('shadowColor', 'rgba(0, 0, 0, 0.2)');
        node.attr('shadowOffsetX', 4);
        node.attr('shadowOffsetY', 4);
        node.attr('lineWidth', 1);
        node.attr('strokeOpacity', 0);
      } else {
        transparent(node);
      }
    }
    const defaultAttr = (node,name) => {
      if(name) {
        node.attr('stroke',setNodeBackground(model.entity_category).stroke || colors.pointStroke);
      }
      transparent(node);
      node.attr('lineWidth', !model.entity_category && 1.5);
    }

    const transparent = (node) => {
      node.attr('shadowBlur', 0);
      node.attr('shadowColor', 'transparent');
      node.attr('shadowOffsetX', 0);
      node.attr('shadowOffsetY', 0);
      node.attr('strokeOpacity', 1);
    }

    // on click node
    if (name === nodeCustomEvents.clicked) {
      if (value) {
        shadowAttr(node,name)
      } else {
        defaultAttr(node,name)
      }
    }

    // Handle node hover
    if (name === nodeCustomEvents.hoverNode) {
      if (value) {
        shadowAttr(node)
      } else {
        if (!isClicked) {
          defaultAttr(node)
        }
      }
    }
    if (name === 'showAnchors') {
      const anchorPoints = item.getContainer().findAll(ele => ele.get('name') === 'anchor-point');
      anchorPoints.forEach(point => {
        if (value || point.get('links') > 0) point.show()
        else point.hide()
      })
    }
  } catch (error) {
    console.error("Error in OnHoverClickEvent:", error);
  }
}
