// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-input {
  border-radius: 2px;
  border: 1px solid var(--Gray-gray-5, #D7D8E0);
  background: var(--Neutral-1, #FFF);
  padding: 8px;
}

.ant-form-item .ant-form-item-label > label {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  font-family: "Roboto";
  font-family: var(--roboto);
  color: var(--Gray-gray-9);
}`, "",{"version":3,"sources":["webpack://./src/common/ui/formEditProject/styles.scss","webpack://./src/common/styles/_mixins.scss"],"names":[],"mappings":"AACA;EACE,kBAAA;EACA,6CAAA;EACA,kCAAA;EACA,YAAA;AAAF;;AAIE;ECRA,eDSgB;ECRhB,iBDQyB;ECPzB,gBDOqB;ECNrB,qBAAA;EDOE,0BAAA;EACA,yBAAA;AAEJ","sourcesContent":["@import '../../styles/mixins';\n.ant-input {\n  border-radius: 2px;\n  border: 1px solid var(--Gray-gray-5, #D7D8E0);\n  background: var(--Neutral-1, #FFF);\n  padding: 8px;\n}\n\n.ant-form-item {\n  .ant-form-item-label > label {\n    @include font(14px,400,18px);\n    font-family: var(--roboto);\n    color: var(--Gray-gray-9);\n  }\n}\n","@mixin font($size: 14px, $weight: 400, $lineHeight: 18px) {\n  font-size: $size;\n  line-height: $lineHeight;\n  font-weight: $weight;\n  font-family: 'Roboto';\n}\n\n@mixin maxHeight() {\n  height: calc(100vh - 7px);\n}\n\n@mixin mobile {\n  @media (max-width: 767px) {\n    @content;\n  }\n}\n\n@mixin tablet {\n  @media (max-width: 1224px) {\n    @content;\n  }\n}\n\n@mixin laptop {\n  @media (max-width: 1440px) {\n    @content;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
