import {useRef} from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "antd";

import {LANGUAGES} from "i18n/constants";
import {onChangeLanguageHandler} from "i18n/utils";
import {languageOption} from "common/utils/language";
import ArrowIcon from "../../icons/arrowIcon";
import {getDeviceSize} from "common/utils/responsive";
import './styles.scss'

const SelectLanguage= () => {
  const { i18n } = useTranslation();
  const ref = useRef(null);
  const activeLang = languageOption(i18n.language);
  const { isMobile} = getDeviceSize();

  return (
    <div className="select-language" ref={ref}>
      <Dropdown
        menu={{
          items: Object.keys(LANGUAGES).map(lng => ({
              key: lng,
              onClick: () => onChangeLanguageHandler(lng),
            label: LANGUAGES[lng].nativeName
        }))
        }}
        trigger={["hover"]}
        className="select-language__dropdown__container"
        overlayClassName="select-language__dropdown"
      >
        <div>{isMobile && <activeLang.icon/>} {activeLang.nativeName} <ArrowIcon className="arrow"/></div>
      </Dropdown>
    </div>
  );
};

export default SelectLanguage;
