import G6 from "@antv/g6";
import ReactDOM from "react-dom";
import {getDeviceSize} from "common/utils/responsive";
import {OnHoverClickEvent} from "./utils/onHoverClickEvent";
import {setNodeBackground} from "./utils/setNodeBackground";
import compressText from "common/utils/compressText";
import {gridPlugin} from "./utils/gridPlugin";
import {wheelControl} from "./utils/wheelControl";

export const nodeCustomEvents = {
	clicked: 'clicked',
	hoverNode: 'hoverNode',
	edgeHover: 'edgeHover',
	edgeActive: 'edgeActive',
	edgeRunning: 'edgeRunning',
}

const rectType = 'rect-node';
const edgeType = 'cubic';

export const colors = {
	text: '#000',
	description: '#626368',
	edgeColor: '#BABFD5',
	pointStroke: '#D7D8E0',
	hoverPointStroke: '#D64686',
	white: '#fff',
	background: '#FAFAFC',
};
const fontFamily = 'Arial';

export const blockHeight = {
	moreThan5: {
		count: 5,
		height: 80,
	},
	moreThan4: {
		count: 4,
		height: 69,
	},
	moreThan3: {
		count: 3,
		height: 64,
	},
};

const {isMobile} = getDeviceSize()
export const initGraph = ({graph, data, ref}) => {
	if (!data) return;

	const width = document.body.clientWidth
	const height = document.body.clientHeight;
	const defaultEvents = ['drag-canvas'];
	const isFireFox = navigator.userAgent.toLocaleLowerCase().includes('firefox');

	if (!isFireFox) {
		defaultEvents.push('scroll-canvas');
	} else {
		defaultEvents.push({
			type: 'zoom-canvas',
			sensitivity: 1,
		});
	}
	if (!isMobile) {
		defaultEvents.push('drag-node');
	}

	const container = ReactDOM.findDOMNode(ref.current);

	graph = new G6.Graph({
		container,
		width,
		height,
		plugins: [gridPlugin],
		modes: {
			default: defaultEvents,
			availableDrag: ['drag-canvas', 'drag-node', 'zoom-canvas'],
			noneAvailable: ['drag-canvas'],
		},
		defaultNode: {
			type: rectType,
			style: {
				width: 136,
				fill: colors.white,
				stroke: colors.pointStroke,
				lineWidth: 1.5,
				radius: 2,
			},
		},
		defaultEdge: {
			type: edgeType,
			style: {
				stroke: colors.edgeColor,
				lineWidth: 1,
				cursor: 'pointer',
				endArrow: {
					path: G6.Arrow.triangle(5, 5, 7),
					d: 7,
					fill: colors.edgeColor,
				},
				startArrow: {
					path: G6.Arrow.triangle(0, 0, 7),
					d: 7
				},
			},
		},
	});

	graph.read(data);
	/**
	 * set active (red border) and inactive for wallet block on click
	 * */
	graph.on('node:mouseenter', (e) => graph.setItemState(e.item, nodeCustomEvents.hoverNode, true));
	graph.on('node:mouseleave', (e) => {
		const group = e.item.getContainer();
		const deleteIcon = group.find((element) => element.get('name') === 'delete-icon');
		if (deleteIcon) {
			deleteIcon.hide();
		}
		graph.setItemState(e.item, nodeCustomEvents.hoverNode, false);
	});

	window.onresize = () => {
		if (!graph || graph.get('destroyed')) return;
		if (!container || !container.scrollWidth || !container.scrollHeight) return;
		graph.changeSize(container.scrollWidth, container.scrollHeight);
	};
	return graph;
};

export const registerFn = () => {
	wheelControl();

	G6.registerEdge(
		'hvh',
		{
			setState(name, value, item) {
				const group = item.getContainer();
				// TODO make active edge on click to wallet
				// edgeAnimation({name, value, item});
				const el = group.get('children')[0];
				if (name === nodeCustomEvents.edgeHover || name === nodeCustomEvents.edgeActive) {
					el.attr('stroke', value ? colors.hoverPointStroke : colors.edgeColor);
					el.attr("endArrow",value ?
            { path: G6.Arrow.triangle(5, 5, 7), d: 7, fill: colors.hoverPointStroke } :
            { path: G6.Arrow.triangle(5, 5, 7), d: 7, fill: colors.edgeColor });
				}
			},
			afterDraw(cfg, group) {
				const shape = group.get('children')[0];
				const midPoint = shape.getPoint(0.5);
				const rectWidth = cfg.text.length * 7;

				group.addShape('rect', {
					attrs: {
						width: rectWidth,
						height: 22,
						fill: colors.background,
						x: midPoint.x - (rectWidth / 2),
						y: midPoint.y - 12,
						zIndex: 10,
					},
				});
				group.addShape('text', {
					attrs: {
						text: cfg?.text,
						x: midPoint.x - (cfg?.text.length * 3.5),
						y: midPoint.y,
						fontSize: 12,
						textBaseline: 'middle',
						fill: '#000',
						zIndex: 10,
						fontFamily,
						cursor: 'pointer',
					},
					name: 'text',
				});
			},
			update: undefined,
		},
		edgeType,
	);

	G6.registerNode(rectType, {
		// draw anchor-point circles according to the anchorPoints in afterDraw
		afterDraw(cfg, group) {
			const bbox = group.getBBox();
			const description = !!cfg.entity_name ? compressText(cfg.address, 6, 4) : cfg?.turnOver;


			// set background
			cfg.style.fill = setNodeBackground(cfg.entity_category).bgColor;
			cfg.style.stroke = setNodeBackground(cfg.entity_category).stroke || colors.pointStroke;
			const textColor = setNodeBackground(cfg.entity_category).textColor;
			const descriptionColor = setNodeBackground(cfg.entity_category).descriptionColor;

			group.addShape('text', {
				attrs: {
					text: cfg?.title,
					y: -5,
					fontSize: 12,
					textAlign: 'center',
					textBaseline: 'middle',
					fill: textColor,
					zIndex: 0,
					fontFamily,
				},
				name: 'title',
				draggable: true,
			});
			// description
			group.addShape('text', {
				attrs: {
					text: description,
					y: cfg.isBreak ? 15 : 10,
					x: 7,
          width:bbox.width,
          height:bbox.height,
					fontSize: 10,
					textAlign: 'center',
          lineAppendWidth: 5,
					textBaseline: 'middle',
					fill: descriptionColor,
					zIndex: 0,
					fontFamily,
				},
				name: 'description',
				draggable: true,
			});
			// delete image
			group.addShape('image', {
				attrs: {
					x: bbox.x + bbox.width - 23,
					y: bbox.y + 3,
					width: 18,
					height: 18,
					img: '/basket.svg',
					cursor: 'pointer',
				},
				name: 'delete-icon',
				visible: isMobile && true,
			});
			const widthCount = (description.length) * 3 + (description.replace('.', '').length >= 10 ? 5 : 3);

			// wallet icon
      group.addShape('image', {
        attrs: {
          x: !cfg.entity_name?.length ? -widthCount : bbox.x + 25,
          y: cfg.isBreak ? 9 : 4,
          img: !!cfg.entity_name?.length ? '/walletIcon.svg' : '/transferIcon.svg',
        },
	      name: 'linked',
        visible:true,
      });
		},
    getHorizontalAnchors(bbox, anchorPos) {
      return bbox.x + (bbox.width * anchorPos[0]) + (anchorPos[0] === 0 ? 0.7 : -0.7);
    },
		getAnchorPoints(cfg) {
			return cfg.anchorPoints || [];
		},
		afterUpdate(cfg, node) {
			const group = node.getContainer();
			const anchorPoints = this.getAnchorPoints(cfg);
			group.find(el => {
				if (el.get('name') === 'anchor-point') el.destroy();
				if (el.get('title') === 'title') el.destroy();
			});
			const bbox = group.getBBox();
			anchorPoints.forEach((anchorPos, i) => {
				const fill = anchorPos.at(2)?.fill || colors.white;
        const borderAnchor  = setNodeBackground(cfg?.entity_category).stroke || colors.pointStroke;

				group.addShape('circle', {
					attrs: {
						r: 4.5,
						x: this.getHorizontalAnchors(bbox, anchorPos),
						y: Math.round(bbox.y) + Math.round(bbox.height) * anchorPos[1],
						fill,
						stroke: borderAnchor,
						lineWidth: 1,
					},
					name: 'anchor-point',
					anchorPointIdx: i,
					links: i,
				});
			});
		},
		// response the state changes and show/hide the link-point circles
		setState(name, value, item) {
      OnHoverClickEvent ({name,value,item})}
	}, 'rect');
};
