import {useState} from "react";
import {useTranslation} from "react-i18next";
import {Tooltip} from "antd";
import Copy from "../../icons/copy";
import {colors} from "../../config/constants";
import {showSuccess} from "common/utils/notification";
import {usePopoverCloseTimer} from "common/utils/usePopoverCloseTimer";
import './index.scss'

const CopyTooltip = ({ children, text }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const {t} = useTranslation();

  const handleClick = (e) => {
    e.stopPropagation();
    navigator.clipboard.writeText(text || "");
    showSuccess(t("notification.copiedClipboard"));
    setTimeout(() => {
      setShowTooltip(false);
    }, 1000);
  };

  usePopoverCloseTimer({ open: showTooltip, setOpen: setShowTooltip, isMobile: true });

  return <Tooltip
    open={showTooltip}
    placement={"bottom"}
    onOpenChange={setShowTooltip}
    color={colors.gray1}
    title={<div onClick={e => handleClick(e)} className="copy-tooltip__overlay__content">
      <p className="copy-tooltip__overlay__text">{text}</p>
      <Copy className="copy-tooltip__overlay__icon"/>
    </div>}
    className={"copy-tooltip"}
    overlayClassName="copy-tooltip__overlay"
    trigger={["focus", "hover", "click"]}
  >
    {children}
  </Tooltip>;
};

export default CopyTooltip;