import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {getEdges, getNodes} from "./utils";
import {initGraph, registerFn} from "./graph";
import {MainLayout} from "common/layout/main/MainLayout";
import {SearchPanel} from "./components/search-panel";
import {BlockDetails} from "pages/Graph/components/block-details";
import {DeleteModal} from "common/ui/modals/deleteModal/deleteModal";
import CreateEditProjectModal from "common/ui/modals/createEditProjectModal";
import {useProjectStore} from "store/projects";
import {graphOnDragCanvas} from "./utils/graphOnDragCanvas";
import {onEdgeClickState} from "./utils/onEdgeClickState";
import {defaultMovingCanvas} from "./utils/defaultMovingCanvas";
import "./creation.styles.scss";

export default function ({objects}) {
	let graph = null;
	const { updateNodePosition, graphCanvasPositions, updateCanvasPosition, updateObjectsPosition, setGraph } = useProjectStore(s => s);
	const ref = React.useRef(null);
	const [el, setEl] = useState(null);
  const { projectId} = useParams();

	useEffect(() => {
		if (graph) {
			graph.setMode('noneAvailable');
		}
		registerFn();
	}, [graph]);

	// TODO remove
	useEffect(() => {
		if (el) {
			const nodes = getNodes(objects?.transfers, objects.wallets);
			const newEdges = getEdges(objects?.transfers || []);
			el.destroyLayout();
			el.changeData({nodes, edges: newEdges});
		}
	}, [el, objects]);

	useEffect(() => {
		if (!graph && !el) {
			const nodes = getNodes(objects?.transfers, objects.wallets);
			const newEdges = getEdges(objects?.transfers || []);

			graph = initGraph({
				graph,
				data: {nodes, edges: newEdges},
				ref,
			});
			setGraph(graph);
			onEdgeClickState(graph);

			setEl(graph);
		}
	}, [objects.wallets]);

	useEffect(() => {
		if (graph) {
			const canvasPosition = graphCanvasPositions[projectId] || {x: 0, y: 0};

			// set the position of canvas by dragging
			graphOnDragCanvas({
				graph,
				canvasPosition,
				updateNodePosition,
				updateCanvasPosition,
				updateObjectsPosition,
				objects,
				id:projectId
			});
		}
	}, [graphCanvasPositions, projectId]);

	useEffect(() => {
		if (!!el) {
			const canvasPosition = graphCanvasPositions[projectId] || {x: 0, y: 0};
			// setup canvas position on reload the Page
			defaultMovingCanvas({canvasPosition, graph: el, projectId, updateCanvasPosition});
		}
	}, [projectId, el]);


  return <MainLayout>
    <SearchPanel graph={el}/>
		<div className='graph-container' ref={ref}></div>
		<BlockDetails graph={el}/>
    <DeleteModal />
    <CreateEditProjectModal/>
	</MainLayout>;
}
