// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-drawer .ant-drawer-header {
  background: var(--Gray-gray-1, #FFF);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
}
.ant-drawer .ant-drawer-body {
  background: var(--Gray-gray-2, #F3F4F9);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 16px 40px 16px !important;
}
.ant-drawer .ant-drawer-body .ant-form {
  padding: 8px;
}
.ant-drawer .ant-drawer-body button {
  width: 100%;
}
.ant-drawer .ant-drawer-close {
  margin-inline-end: 0;
  width: auto;
  height: auto;
}
.ant-drawer .ant-drawer-title {
  margin-right: 8px;
  text-align: center;
}
.ant-drawer .ant-drawer-content-wrapper {
  width: 100% !important;
}`, "",{"version":3,"sources":["webpack://./src/common/ui/antdDrawer/styles.scss"],"names":[],"mappings":"AAGE;EACE,oCAAA;EACA,2CAAA;AAFJ;AAIE;EACE,uCAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,uCAAA;AAFJ;AAGI;EACE,YAAA;AADN;AAGI;EACE,WAAA;AADN;AAIE;EACE,oBAAA;EACA,WAAA;EACA,YAAA;AAFJ;AAIE;EACE,iBAAA;EACA,kBAAA;AAFJ;AAIE;EACE,sBAAA;AAFJ","sourcesContent":["@import \"../../styles/mixins\";\n\n.ant-drawer {\n  .ant-drawer-header {\n    background: var(--Gray-gray-1, #FFF);\n    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);\n  }\n  .ant-drawer-body {\n    background: var(--Gray-gray-2, #F3F4F9);\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    padding: 16px 16px 40px 16px !important;\n    .ant-form {\n      padding: 8px;\n    }\n    button {\n      width: 100%;\n    }\n  }\n  .ant-drawer-close {\n    margin-inline-end: 0;\n    width: auto;\n    height: auto;\n  }\n  .ant-drawer-title {\n    margin-right: 8px;\n    text-align: center;\n  }\n  .ant-drawer-content-wrapper {\n    width: 100% !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
