import {create} from 'zustand';
import request from "../api";

export const useMeStore = create((set) => ({
	me: null,
	getMeInfo: async () => {
		try {
			const { data } = await request.get("/user/info/");
			set(state => state.me = data);
		} catch (e) {
			console.log(e);
		}
	},
}))