import * as React from "react"
const KytLogo = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={55}
		height={24}
		fill="none"
		{...props}
	>
		<path
			fill="#232325"
			d="M4.095 6.884h1.601c.11 0 .212-.05.28-.135.16-.198.33-.389.51-.57a8.053 8.053 0 0 1 2.568-1.765 7.894 7.894 0 0 1 3.141-.647c1.091 0 2.148.219 3.142.647a8.053 8.053 0 0 1 2.567 1.765 8.228 8.228 0 0 1 1.731 2.616A8.3 8.3 0 0 1 20.268 12c0 1.114-.214 2.19-.633 3.205a8.19 8.19 0 0 1-1.731 2.616 8.053 8.053 0 0 1-2.567 1.765 7.895 7.895 0 0 1-3.142.647 7.855 7.855 0 0 1-3.141-.647 8.052 8.052 0 0 1-2.567-1.765 9.126 9.126 0 0 1-.51-.57.36.36 0 0 0-.28-.135H4.094a.185.185 0 0 0-.153.286C5.69 20.174 8.747 22.01 12.22 22c5.458-.014 9.834-4.537 9.78-10.102C21.945 6.42 17.576 2 12.195 2 8.731 2 5.687 3.833 3.942 6.598c-.077.123.01.286.153.286ZM2.07 12.146l3.232 2.605c.121.098.296.01.296-.146v-1.768h7.153c.1 0 .182-.083.182-.186V11.35a.185.185 0 0 0-.182-.186H5.598V9.395c0-.155-.177-.244-.296-.146L2.07 11.853A.185.185 0 0 0 2 12a.19.19 0 0 0 .07.146ZM31.198 7.047V17h-1.716V7.047h1.716Zm5.982 0-4.033 4.792-2.297 2.434-.301-1.703 1.64-2.023 2.899-3.5h2.092ZM35.334 17l-3.274-4.717 1.182-1.169L37.372 17h-2.038Zm3.944-9.953 2.31 4.75 2.311-4.75h1.9l-3.349 6.289V17h-1.73v-3.664l-3.349-6.29h1.907Zm11.827 0V17h-1.703V7.047h1.703Zm3.123 0v1.367h-7.922V7.047h7.922Z"
		/>
	</svg>
)
export default KytLogo
