import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useParams} from "react-router-dom";
import {ConfigProvider, Dropdown} from 'antd';
import MenuOptions from "common/icons/menuOptions";
import {useNetworkStore} from "store/networks";
import {useProjectStore} from "store/projects";
import {modalTypes, useModalStore} from "store/modal";
import EditProjectDrawer from "../editProjectDrawer";

export const HeaderMenuDropdown = (rest) => {
  const {networks,getNetworks} = useNetworkStore((state) => state);
  const [open, setOpen] = useState(false);
  const { projectId} = useParams();
  const { createProject } = useProjectStore((state) => state);
  const { openModal } = useModalStore((state) => state);
  const { icon, width, singleProject} = rest;

  const showDefaultDrawer = () => {
    setOpen(true);
  };

  useEffect( () => {
    getNetworks();
  }, []);

  const onClick = useCallback(
    async ({ key }) => {
        switch (key) {
          case 'editProject':
            showDefaultDrawer();
            break;
          case 'deleteProject':
            openModal(modalTypes.deleteProject, {
              network: projectId,
              editName: singleProject?.name,
            });
            break;
          default:
            await createProject({id: key});
        }
    }, [projectId, singleProject?.name, openModal, createProject]
  );

  const mobileItem = useMemo(() => {
    return networks.map(item => ({
      label: `${item.name} (${item.code})`,
      key: item.id
    }))
  }, [networks]);

  const items = rest.items || mobileItem ;

	return <ConfigProvider
		theme={{
			components: {
				Dropdown: {
					colorError: '#E5424C',
					paddingXXS: 0,
					borderRadiusSM: 0,
				}
			}
		}}
	>
		<Dropdown
			align={{offset: [0, 11]}}
			overlayStyle={{ width: width || 160}}
			menu={{items, onClick}}
			trigger={['click']}
			{...rest}
		>
			{icon ? icon : <MenuOptions />}
		</Dropdown>
		<EditProjectDrawer open={open} setOpen={setOpen} name={singleProject?.name} />
	</ConfigProvider>;
}

