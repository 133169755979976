import { useState} from "react";
import { Popover as PopoverAnt } from "antd";
import {getDeviceSize} from "common/utils/responsive";
import {usePopoverCloseTimer} from "common/utils/usePopoverCloseTimer";
import './styles.scss'

const Hint = (props) => {
  const {
    content,
      trigger,
      children,
      open: externalOpen,
      align,
      className,
      style,
  } = props;
  const isMobile = getDeviceSize()
  const [open, setOpen] = useState(externalOpen);
  usePopoverCloseTimer({open,setOpen,isMobile:true})

  return (
    <PopoverAnt
      placement="bottomLeft"
      content={content}
      trigger={!isMobile ? "click" : trigger}
      overlayClassName="hint-overlay"
      align={align || { targetOffset: [6, 15] }}
      open={open}
      mouseEnterDelay={0.7}
      mouseLeaveDelay={0.3}
      className={className}
      onOpenChange={setOpen}
      {...props}
    >
      <div className="hint-content" style={style}>
        {children}
      </div>
    </PopoverAnt>
  );
};

export default Hint;
