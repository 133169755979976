import G6 from '@antv/g6';

function calculateValue() {
	const screenWidth = window.outerWidth;
	const width1 = 1920;
	const value1 = 0.05;
	const width2 = 1352;
	const value2 = 0.017;

	const k = (value1 - value2) / (width1 - width2);

	if (screenWidth <= width2) {
		return value2 - k * (width2 - screenWidth);
	}

	return value1 - k * (screenWidth - width1);
}

export function wheelControl() {
	G6.registerBehavior('scroll-canvas', {
		getEvents: function getEvents() {
			return {
				wheel: 'onWheel',
			};
		},
		onWheel: function(ev){
			const graph = this.graph;
			if (ev.ctrlKey) {
				const canvas = graph.get('canvas');
				const point = canvas.getPointByClient(ev.clientX, ev.clientY);
				let ratio = graph.getZoom();
				const DELTA = calculateValue();

				if (ev.wheelDelta > 0) {
					ratio = ratio + ratio * DELTA;
				} else {
					ratio = ratio - ratio * DELTA;
				}
				graph.zoomTo(ratio, {
					x: point.x,
					y: point.y,
				});
			} else {
				const x = ev.deltaX || ev.movementX;
				let y = ev.deltaY || ev.movementY;
				if (!y && navigator.userAgent.indexOf('Firefox') > -1) y = (-ev.wheelDelta * 125) / 3
				graph.translate(-x, -y);
			}
			ev.preventDefault();
		},
	});
}
