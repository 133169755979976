import {useEffect} from "react";
import {useDrawerStore} from "../../store/drawer";

const UseHoverNodeEffect = ({ graph,value }) => {
  const { hoverNode } = useDrawerStore(s => s);
  const model = hoverNode?.getModel();

  useEffect(() => {
    if (hoverNode) {
      graph.updateItem(hoverNode, {
        style: {
          shadowBlur: 10 * value,
          shadowOffsetX: 4 * value,
          shadowOffsetY: 4 * value,
          shadowColor: 'rgba(0, 0, 0, 0.15)',
          // lineWidth:0,
          strokeOpacity: !model.entity_category ? 0 : 1
        },
      });
    }
    return () => {
      if (hoverNode) {
        graph.updateItem(hoverNode, {
          style: {
            shadowBlur: 0,
            shadowOffsetX: 0,
            shadowOffsetY: 0,
            shadowColor: 'transparent',
            // lineWidth:1,
            strokeOpacity:1
          },
        });
      }
    };
  }, [value, hoverNode,graph]);
}
export default UseHoverNodeEffect;