const IconPlus = (props) => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M3.33624 10.8276C3.10976 10.8276 2.90941 10.75 2.73519 10.5948C2.5784 10.4224 2.5 10.2241 2.5 10C2.5 9.77586 2.5784 9.58621 2.73519 9.43103C2.90941 9.25862 3.10976 9.17241 3.33624 9.17241H8.90244C9.05923 9.17241 9.13763 9.10345 9.13763 8.96552V3.35345C9.13763 3.12931 9.22474 2.93103 9.39895 2.75862C9.57317 2.58621 9.77352 2.5 10 2.5C10.2265 2.5 10.4268 2.58621 10.601 2.75862C10.7753 2.93103 10.8624 3.12931 10.8624 3.35345V8.96552C10.8624 9.10345 10.9408 9.17241 11.0976 9.17241H16.6638C16.8902 9.17241 17.0819 9.25862 17.2387 9.43103C17.4129 9.58621 17.5 9.77586 17.5 10C17.5 10.2241 17.4129 10.4224 17.2387 10.5948C17.0819 10.75 16.8902 10.8276 16.6638 10.8276H11.0976C10.9408 10.8276 10.8624 10.8966 10.8624 11.0345V16.6466C10.8624 16.8707 10.7753 17.069 10.601 17.2414C10.4268 17.4138 10.2265 17.5 10 17.5C9.77352 17.5 9.57317 17.4138 9.39895 17.2414C9.22474 17.069 9.13763 16.8707 9.13763 16.6466V11.0345C9.13763 10.8966 9.05923 10.8276 8.90244 10.8276H3.33624Z"
                fill="#B9BBC2"
            />
        </svg>
    );
};

export default IconPlus;
