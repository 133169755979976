import cn from "classnames";
import './button.scss'

const Button = ({
    children,
    className,
    ...rest
}) => {
    return (
        <button type={"button"} className={cn("button", className)} {...rest}>
            {children}
        </button>
    );
};

export default Button;
