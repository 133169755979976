export function edgePercentageAnchors(n, position = 0) {
	const edgeHeight = 8
	const range = n < 5 ? 4 : 2
	const dotRadius = 4.5
	const totalHeight = edgeHeight * 2 + (dotRadius * 2 * n) + range * (n-1)

	const calcPercentage = (height) =>
		Number((height * 100 / totalHeight / 100).toFixed(5))

	let currentDotHeight = edgeHeight + dotRadius
	const respArr = [calcPercentage(currentDotHeight)];

	for(let i = 1; i < n; i++) {
		currentDotHeight = currentDotHeight + range + dotRadius * 2
		respArr.push(calcPercentage(currentDotHeight))
	}

	return respArr
}
