import React, {useEffect} from "react";
import {useParams, Link} from "react-router-dom";
import BackButton from "common/icons/backButton";
import {HeaderMenuDropdown} from "common/ui/headerMenuDropdown/headerMenuDropdown";
import {useProjectStore} from "store/projects";
import {modalTypes} from "store/modal";
import {useTranslation} from "react-i18next";
import {paths} from "../../../config/paths";
import SelectLanguage from "common/ui/selectLanguage";
import EditableInput from "./components/inputTitle";
import "../mainHeader.scss";

export function MainContent ({isMobile}) {
  const { getSingleProject,singleProject } = useProjectStore(state => state);
  const {t} = useTranslation();
  const { projectId } = useParams();

  useEffect(() => {
      getSingleProject({id:projectId});
  }, [projectId, getSingleProject]);

  const items = [
    {
      label: (<span>{t("initial.editName")}</span>),
      key: modalTypes.editProject,
    },
    {
      label: (<span>{t("initial.deleteProject")}</span>),
      danger: true,
      key: modalTypes.deleteProject,
    },
  ];

  return <>
		<Link className="logo" to={paths.HOME}><BackButton/> {!isMobile && t("initial.projects")}</Link>
    <EditableInput singleProject={singleProject}/>
    <div className='header-left'>
      {isMobile ? <HeaderMenuDropdown
        singleProject={singleProject}
        items={items}/> : <>
        <SelectLanguage/>
        <div className="menu">
          <span>john.doe@gmail.com</span>
        </div>
      </>}
    </div>
  </>
}
