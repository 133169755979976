import React from "react";

const FitCenterIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clip-path="url(#clip0_288_119)">
      <path
        d="M1.33325 4.66683V1.3335H4.66659M14.6666 4.66683V1.3335H11.3333M4.66659 14.6668H1.33325V11.3335M11.3333 14.6668H14.6666V11.3335"
        stroke="#8C8D94" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <circle cx="8" cy="8" r="2" fill="#8C8D94"/>
    </g>
    <defs>
      <clipPath id="clip0_288_119">
        <rect width="16" height="16" fill="white"/>
      </clipPath>
    </defs>
  </svg>
)
export default FitCenterIcon;
