import React, {useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {Dropdown} from "antd";
import Plus from "common/icons/plus";
import ButtonComponent from "common/ui/button/button";
import {SearchInput} from "common/ui/searchInput/searchInput";
import {useNetworkStore} from "store/networks";
import {useProjectStore} from "store/projects";
import "./initial-search-bar.scss";

export const InitialSearchBar = () => {
  const { t } = useTranslation();
  const {networks,getNetworks} = useNetworkStore((state) => state);
	const { createProject } = useProjectStore((state) => state);

  const onChange = async ({key}) => {
	  await createProject({id: key});
  }

  useEffect( () => {
    if (networks.length === 0) {
	    getNetworks();
    }
  }, [networks]);

  const items = useMemo(() => {
    return networks.map(item => ({
      label: `${item.name} (${item.code})`,
      key: item.id
    }))
  }, [networks]);

	return <div className="initial-search-panel">
		<SearchInput onChange={null} />
		<div className="project-block">
      <Dropdown
        menu={{items, onClick: onChange}}
        paddingBlock={20}
        trigger={['click']}
        overlayStyle={{width: '200px'}}
        placement="bottomLeft">
        <ButtonComponent
          text={t("initial.newProject")}
          colorType="pink"
          icon={<Plus/>}
          width={160}
          className="init-new-project"
        />
      </Dropdown>
		</div>
	</div>
}
