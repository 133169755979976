import React from "react";
import {useProjectStore} from "store/projects";
import CopyContent from "common/ui/copyContent";
import {useTranslation} from "react-i18next";

const Head = () => {
  const { drawerData } = useProjectStore(s => s);
  const {t} = useTranslation();

  return (
    <div className={"sidebar_head__wrapper"}>
      <div className={"sidebar_head__inner-wrapper"}>
        <h3>{drawerData?.entity_name ? drawerData?.entity_name : t("common.unknownWallet")}</h3>
        <p> <CopyContent children={drawerData?.address} copyText={drawerData?.address}/></p>
      </div>
    </div>
  );
};

export default Head;
