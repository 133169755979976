import React, {useEffect} from "react";
import {MainLayout} from "common/layout/main/MainLayout";
import {EmptyBlock} from "./empty-block/empty-block";
import {InitialTable} from "./initial-table/initial-table";
import {useProjectStore} from "store/projects";
import CreateEditProjectModal from "common/ui/modals/createEditProjectModal";
import Spinner from "common/ui/spinner";
import useQueryState from "hooks/useQueryState";
import SelectLanguage from "common/ui/selectLanguage";
import {getDeviceSize} from "common/utils/responsive";
import "./initial.scss";

export const Initial = () => {
  const { projects, fetching, updateProjectParams, params} = useProjectStore(state => (state));
  const { page } = useQueryState();
  const {isMobile} = getDeviceSize();

  useEffect(() => {
    updateProjectParams({params: {...params, page}, fetching: true, reload: true});
  }, []);

  if (fetching) {
    return <MainLayout hideProject>
      <Spinner/>
    </MainLayout>
  }

 return (
   <MainLayout hideProject>
     <div className={(!projects?.results?.length && projects.previous === null) ? 'initial' : 'initial-table'}>
       {(!projects?.results?.length && projects.previous === null) ? <EmptyBlock/> : <InitialTable/>}
     </div>
     <div id="footer">
       {isMobile && <SelectLanguage/>}
     </div>
     <CreateEditProjectModal/>
   </MainLayout>
 )
}
