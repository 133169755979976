// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.copy-content svg {
  vertical-align: middle;
  cursor: pointer;
  margin-left: 4px;
}`, "",{"version":3,"sources":["webpack://./src/common/ui/copyContent/styles.scss"],"names":[],"mappings":"AACE;EACE,sBAAA;EACA,eAAA;EACA,gBAAA;AAAJ","sourcesContent":[".copy-content {\n  svg {\n    vertical-align: middle;\n    cursor: pointer;\n    margin-left: 4px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
