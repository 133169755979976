import React, {useMemo} from "react";
import {useLocation} from "react-router-dom";

import {paths} from "common/config/paths";
import {useProjectStore} from "store/projects";
import Logo from "common/icons/logo";
import EditableInput from "../inputTitle";
import {getDeviceSize} from "common/utils/responsive";
import {useTranslation} from "react-i18next";

const HeaderCenter = ({singleProject}) => {
  const {pathname} = useLocation();
  const { projects } = useProjectStore(state => state);
  const { isMobile } = getDeviceSize();
  const {t} = useTranslation();

  const isMobileWithProject = useMemo(() => {
    return isMobile && !!projects.results?.length;
  }, [projects.results, isMobile]);

  if (pathname === paths.HOME) {
    return (
      <div className="edit-wrapper">
        <span>{isMobileWithProject ? t("initial.project") : <Logo />}</span>
      </div>
    );
  }

  return <EditableInput singleProject={singleProject} />;
}
export default HeaderCenter;