import {useEffect} from "react";

export const usePopoverCloseTimer = ({open,setOpen,isMobile= false}) => {
  useEffect(() => {
    let timer;
    if (isMobile && open) {
      timer = setTimeout(() => {
        setOpen(false);
      }, 2000);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [open, isMobile]);
}