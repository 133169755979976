import {Link} from "react-router-dom";
import {HeaderMenuDropdown} from "common/ui/headerMenuDropdown/headerMenuDropdown";
import React, { useMemo} from "react";
import KytLogo from "common/icons/kytLogo";
import RedPlus from "common/icons/redPlus";
import SelectLanguage from "common/ui/selectLanguage";
import {useTranslation} from "react-i18next";
import Logo from "common/icons/logo";
import {useProjectStore} from "store/projects";
import "../mainHeader.scss";

export function InitialContent ({isMobile}) {
  const { t } = useTranslation();
  const { projects,fetching } = useProjectStore(state => state);

  const logo = (<span className='main-logo'><Logo/></span>);

  const mainLogo = useMemo(() => {
    if (!projects.results?.length && isMobile && !fetching || !isMobile) {
      return logo
    }
  }, [isMobile,projects.results?.length,fetching])

  const isMobileWithProject = useMemo(() => {
    return isMobile && !!projects.results?.length;
  }, [projects.results, isMobile]);

  return <div className="wrapper">
		<Link className="logo" to={process.env.REACT_APP_BASE_URL}><KytLogo /></Link>
		{isMobileWithProject && <div className="edit-wrapper">
			<span>{t("initial.project")}</span>
		</div>}
    {mainLogo}
    <div className='header-left'>
      {!isMobile && <SelectLanguage /> }
      {isMobileWithProject ? <HeaderMenuDropdown width={215} icon={<RedPlus />} /> :
        <div className="menu">
          <span>john.doe@gmail.com</span>
      </div>}
    </div>
	</div>
}
