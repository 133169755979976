import React from "react";
import { Layout } from 'antd';
import {getDeviceSize} from "common/utils/responsive";
import {useLocation} from "react-router-dom";
import {paths} from "../../../router/paths";
import {MainContent} from "./contents/mainContent";
import {InitialContent} from "./contents/initialContent";
import {Content} from "antd/lib/layout/layout";
import "./mainHeader.scss";

const { Header } = Layout;

export const MainHeader = () => {
	const {pathname} = useLocation();
	const { isMobile } = getDeviceSize();

	const getHeaderContent = () => {
		switch (pathname) {
			case paths.main:
        return <InitialContent isMobile={isMobile} />;
			default:
        return <MainContent isMobile={isMobile} />
		}
	}

	return <Content>
		<Header breakpoint="xs">
			{getHeaderContent()}
		</Header>
	</Content>
};
