import {create} from 'zustand';
import {devtools, persist} from "zustand/middleware";
import request from "../api";
import {showError, showSuccess} from "common/utils/notification";
import {DEFAULT_PAGE_LIMIT} from "pages/Initial/initial-table/constants";
import {useDrawerStore} from "./drawer";
import {findItemPlace} from "../pages/Graph/utils/addItem";

const { clearNode } = useDrawerStore.getState();
let coordinates = [];
export const useProjectStore = create()(devtools(persist((set, get) => ({
	graphEl: {},
	projects: {},
  walletTransfers: null,
  singleProject:{},
	searchData: {},
  drawerData:{},
	objects: {
		transfers: null,
		wallets: null,
	},
	params: {
		size: DEFAULT_PAGE_LIMIT,
		page: 1,
	},
	fetching: false,
	showMoreLoader: false,
	hideShowMoreButton: false,
	walletTransferLoader:  false,
  drawerLoader:false,
	graphData: {
		nodes: [],
		combos: [],
		edges: [],
	},
  graphCanvasPositions: {},

	clearWalletTransfers: () => {
		set(() => ({walletTransfers: null}));
	},
	setGraph: (graphEl) => {
		set(() => ({graphEl}), false, 'setGraph');
	},
  getWalletTransfer: async ({id, params }) => {
    try {
			const transfersData = get().walletTransfers;
			// block request if count less than 20 items
			if (transfersData?.count && transfersData.count < 20) return;
			if (transfersData?.next_token) {
				params['next_token'] = transfersData.next_token;
			}
      const { data } = await request.get(`/graph/wallets/${id}/transfers/`, {params});
			const transfers = get().walletTransfers?.transfers || [];
			for (const item of data?.transfers) {
				transfers.push(item);
			}

      set(() => ({walletTransfers: {...data, transfers}}), false, 'getWalletTransfer');
    } catch (_) {
      showError();
    }
  },
	/**
	 * transfer from the table of the Wallet in the drawer
	 * */
	updateWalletTransfers: (transfer) => {
		set(s => {
			const item = s.walletTransfers?.transfers.find(item => item.ext_id === transfer.ext_id);
			const transfers = s.walletTransfers?.transfers?.map(trf => item && trf.ext_id === item.ext_id ? ({...item, id: transfer.id }) : trf);
			return {walletTransfers: {transfers}}
		}, false, 'updateWalletTransfers');
	},
	/**
	 * @canvasPosition: { x: number, y: number }
	 * */
  updateCanvasPosition: (graphId, position) =>
    set((state) => ({
      graphCanvasPositions: {
        ...state.graphCanvasPositions,
        [graphId]: position,
      },
    })),
	/**
	 * updating single Node on the Graph by API
	 * @data: graph Node
	 * */
	updateNodePosition: async (body) => {
		try {
			const { id, x, y } = body;
			await request.post(`/graph/wallets/${id}/update-position/`, { pos_x: x, pos_y: y }, false, 'updateNodePosition');
		} catch (_) {
			showError();
		}
	},
	/**
	 * DTO data to wallet object {id, address, pos_x, pos_y}
	 * @wallets: wallet[
	 *   {
	 * 			"id": string,
	 * 			"address": string,
	 * 			"entity_name": string,
	 * 			"entity_category": string,
	 * 			"pos_x": number,
	 * 			"pos_y": number
	 * 		}
	 * ]
	 * @data: [
	 * {
	 *     "id": string,
	 *     "title": string,
	 *     "name": "node",
	 *     "layoutOrder": number,
	 *     "x": number,
	 *     "y": number,
	 *     "type": string,
	 *     "_order": number
	 * }
	 * ]
	 * */
	updateStatusShowMore: (status) => {
		set(() => ({hideShowMoreButton: status}));
	},
	/*
	* @params: {page: integer, size: integer}
	* */
	getProjects: async (fetching) => {
		set(() => ({fetching}));
		try {
			const {data} = await request.get(`/graph/projects`, {params: get().params});
			set(state => state.projects = data);
		} catch (_) {
			showError();
		} finally {
			set(() => ({fetching: false}));
		}
	},
	loadProjects: async () => {
		set(() => ({showMoreLoader: true}));
		try {
			const {data} = await request.get(`/graph/projects`, {params: get().params});
			const results = (get().projects?.results || []).concat(data?.results);
			set(() => ({
				projects: {...data, results},
			}));
		} catch (_) {
			showError();
		} finally {
			set(() => ({showMoreLoader: false}));
		}
	},
	/*
	* filters: {
	* 	page: number;
	* 	size: number;
	* }
	* reload flag for recalling request to /projects/
	* */
	updateProjectParams: async ({params, reload, fetching}) => {
		set(() => ({params}));

		if (reload) {
			await get().getProjects(fetching);
		}
	},

	/*
	* @id: uniq string of project id
	* */
	deleteProject: async (data,cb) => {
		try {
			await request.post(`/graph/projects/${data.id}/delete/`);

			function removeItem(results) {
				const index = results.findIndex(item => item.id === data.id);
				results.splice(index, 1);
				return results;
			}

			set((state) => {
        state.getProjects();
        return {
          projects: {
            ...state.projects,
            results: removeItem(state.projects?.results)
          },
        };
      });
			showSuccess();
      cb();
		} catch (error) {
			showError();
		}
	},
	/*
	* @data: {name: string}
	* @id: uniq string of project id
	* */
	editProject: async (project, cb) => {
		try {
			const {data} = await request.post(`/graph/projects/${project.id}/edit/`, {name: project.name});
			// update by item id
			set(() => ({singleProject: data}));

			if (!!get().projects?.results) {
				set((state) => ({
					projects: {
						...state.projects,
						results: state.projects.results.map(item => item.id === data.id ? data : item)
					}
				}));
			}

			cb();
			showSuccess();
		} catch (_) {
			showError();
		}
	},
	/*
	* network берем из запроса API/catalog/networks/
	* @data: { network: $uuid, name: string }
	* @cb: () => void;
	* */
	createProject: async (data) => {
		try {
			await request.post('/graph/projects/create/', {network: data.id});
			showSuccess();
			get().getProjects();
		} catch (_) {
			showError();
		}
	},
	addWalletCoordinates: (singleWallet, wallets) => {
		const item = findItemPlace(wallets);
		let pos_x = item.pos_x;
		let pos_y = item.pos_y;
		const newCoordinates = `${pos_x}${pos_y}`;

		if (coordinates.includes(newCoordinates)) {
			pos_x += 300;
			pos_y += 120;
			coordinates = [];
		}

		const newWallet = {...singleWallet, x: pos_x, y: pos_y, pos_x, pos_y};
		if (typeof pos_x === 'number') {
			get().updateNodePosition(newWallet);
		}

		coordinates.push(newCoordinates);
		return newWallet;
	},
	/*
	* @id: Project ID string
	* */
	getObjects: async ({id}) => {
		try {
			let emptyCount = 0;
			const {data} = await request.get(`/graph/projects/${id}/objects/`);

			const fullItems = [];
			const updatedItems = [];

			for (const item of data.wallets) {
				if (!!item.pos_x || !!item.pos_y) {
					fullItems.push(item);
				} else {
					emptyCount++;
				}
			}

			if (!!emptyCount) {
				for (const item of data.wallets) {
					if (!!item.pos_x || !!item.pos_y) {
						updatedItems.push(item);
					} else {
						const newItem = get().addWalletCoordinates(item, fullItems);
						updatedItems.push(newItem);
					}
				}
			}
			set(() => ({objects: {wallets: !emptyCount ? data.wallets : updatedItems, transfers: data.transfers}}));
		} catch (error) {
			if (error.status === 404) {
				window.location.replace('/');
			}
			showError();
		}
	},
	/**
	 * data: {key: string, values: null || {} || []}
	 * */
	resetStateByKey: (data) => {
		const resetKeys = {};
		for (const {key, value} of data) {
			resetKeys[key] = value;
		}
		set(() => (resetKeys), false, 'resetStateByKey');
	},
	/*
	* @data: { query: String - search input, id: String - project if };
	* return => {
			query: string,
			accounts: [{
				ext_id: string
				account_id: string,
				address: string
			}],
			transfers: [{
				ext_id: string;
				transfer_id: string;
				tx_hash: string;
				amount: number;
				sender_address: string;
				recipient_address: string;
			}]
		}
	* */
	searchProject: async ({ query, id, cb}) => {
		try {
			const { data } = await request.post(`/graph/projects/${id}/search/`, {query});
			set(() => ({searchData: data}));
		} catch (_) {
			showError();
		} finally {
			if (cb) {
				cb();
			}
		}
	},
	addWallet: async ({id, ext_id, cb}) => {
		set(() => ({walletTransferLoader:true}))
		try {
			const { data } = await request.post(`/graph/projects/${id}/add-wallet/`, {ext_id});
			set(state => ({
				searchData: {
					...state.searchData,
					wallets: state.searchData.wallets.map((item) => item.address === data.address ? ({...item, id: data.id}) : item)
				}
			}));
			showSuccess();
			await get().getObjects({id});

			if (cb) {
				cb(data);
			}
		} catch (_) {
			showError();
		} finally {
			set(() => ({walletTransferLoader:false}))
		}
	},
	addTransfer: async ({id, ext_id}) => {
		set(() => ({walletTransferLoader:true}))
		try {
			const { data } = await request.post(`/graph/projects/${id}/add-transfer/`, {ext_id});
			await get()?.getObjects({id});
			set(state => {
				if (state.searchData?.transfers?.length && !!data) {
					return {
						searchData: {
							...state.searchData,
							transfers: state.searchData?.transfers?.map((item) => item.tx_hash === data.tx_hash ? ({...item, id: data.id}) : item)
						}
					}
				}
				return state;
			});
			showSuccess();
			// if (cb) {
			// 	cb(data);
			// }
			return data;
		} catch (_) {
			showError();
		} finally {
			set(() => ({walletTransferLoader:false}))
		}
	},
	deleteWalletProject:async ({projectId, data}) => {
		try {
			await request.post(`/graph/wallets/${data.id}/delete/`);
			showSuccess();
			await get().getObjects({id: projectId});
      clearNode();
		} catch (error) {
			showError();
		}
	},
	deleteTransferProject:async (data) => {
		try {
			await request.post(`/graph/transfers/${data.id}/delete/`);
			function removeItem(results) {
				const index = results.findIndex(item => item.id === data.id);
				results.splice(index, 1);
				return results;
			}
			set((state) => ({
				objects: {
					...state.objects,
					transfers: removeItem(state.objects?.transfers)
				},
			}));
			showSuccess();
      clearNode();
		} catch (error) {
			showError();
		}
	},
  getSingleProject: async ({id}) => {
    try {
      const {data} = await request.get(`/graph/projects/${id}/`);
      set(state => state.singleProject =  data);
    } catch (_) {
      showError();
    }
  },
  getWalletOverview: async ({id}) => {
		if (id) {
			set(() => ({drawerLoader: true}));
			try {
				const {data} = await request.get(`/graph/wallets/${id}/overview/`);
				set(state => state.drawerData =  data);
			} catch (_) {
				showError();
			} finally {
				set(() => ({drawerLoader: false}));
			}
		}
  },
  getTransferOverview: async ({id}) => {
    set(() => ({drawerLoader: true}));
    try {
      const {data} = await request.get(`/graph/transfers/${id}/`);
      set(state => state.drawerData =  data);
    } catch (_) {
      showError();
    } finally {
      set(() => ({drawerLoader: false}));
    }
  },
	resetSearchProject: async () => {
		set(() => ({searchData:null}));
	},
}), {
	name: 'graph',
	partialize: (state) => ({
    graphCanvasPositions: state.graphCanvasPositions,
	}),
})));



