import React from "react";
import Head from "./components/head";
import List from "./components/list";
import MainInfo from "./components/mainInfo/mainInfo";
import './sideBar.scss'

const WalletDetails = ({graph}) => {
  return <div className={"sidebar_inner-wrapper"}>
    <Head/>
    <MainInfo/>
    <div className={"sidebar_content__wrapper"}>
      <List graph={graph}/>
    </div>
  </div>
};

export default WalletDetails;
