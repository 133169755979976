import {useEffect, useState} from "react";
import {debounce} from "lodash";
import {useProjectStore} from "store/projects";
import {useFetchTransfersHook} from "../../hooks/useFetchTransferHook";
import {getDeviceSize} from "common/utils/responsive";

const dataObj = {
  get(key) {
    return this[key];
  },
  set(key, value) {
    this[key] = value;
  }
}
const WALLET_ID = 'WALLET_ID';
const WALLET = 'WALLET';
let activated = false;
const LoadDataTransfer = ({parentRef}) => {
  const {isMobile} = getDeviceSize()
  const [isScrolled, setIsScrolled] = useState(false);
  const {fetchTransfers} = useFetchTransfersHook();
  const { drawerData, walletTransfers } = useProjectStore(s => s);

  const loadData = (event) => {
    if (event.target) {
      const wrapper = event.target;
      const { scrollTop, scrollHeight, clientHeight } = wrapper;
      const isNearBottom = (scrollTop + clientHeight + 200) >= scrollHeight;

      if (isNearBottom && !drawerData?.tx_hash && !!dataObj.get(WALLET)?.next_token) {
        fetchTransfers(dataObj.get(WALLET_ID));
      }
    }
  };

  const handleScroll = (event) =>{
    loadData(event);

    if (parentRef.current) {
      const scrollTop = parentRef.current.scrollTop;
      setIsScrolled(scrollTop > 50);
    }
  };

  useEffect(() => {
    dataObj.set(WALLET, walletTransfers);
    dataObj.set(WALLET_ID, drawerData?.id);
  }, [walletTransfers, drawerData]);

  function callScrollEvent(init = true) {
    if (init) {
      parentRef?.current.addEventListener("scroll", debounce(handleScroll, 300));
    } else {
      parentRef?.current.removeEventListener("scroll", debounce(handleScroll, 300));
    }
  }

  useEffect(() => {
    if (parentRef?.current) {
      if (isMobile) {
        callScrollEvent();
      }
      if (!isMobile && !activated) {
        callScrollEvent();
        activated = true;
      }
    }

    return () => {
      if (!isMobile && parentRef?.current) {
        callScrollEvent(false);
      }
    };
  }, [parentRef?.current]);

  return {
    isScrolled,
  }
}
export default LoadDataTransfer;