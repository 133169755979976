import React from "react";
import cn from "classnames";
import {COLUMN_CLASSNAME, EColumns} from "../../../../../../constants";
import formatDate, {EFormats} from "common/utils/formatDate";

const HashDesktop = ({isMobile,item}) => {
  if(!isMobile) {
    return (
      <div className={cn(COLUMN_CLASSNAME, `_${EColumns.hash}`)}>
        <p>{item.tx_hash}</p>
        <span>{item.occurred_at ? formatDate(item.occurred_at, EFormats.LT) : "―"}</span>
      </div>
    )
  }
}
export default HashDesktop;