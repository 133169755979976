// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../common/images/rocket.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-dropdown-menu {
  border-radius: 2px !important;
}

.initial {
  height: calc(100vh - 50px);
  width: 100%;
  background: transparent url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat 80% 100%;
  display: flex;
  justify-content: center;
}
@media (max-width: 767px) {
  .initial {
    background-position: -15% 90%;
    background-size: 50%;
  }
}

.initial-table {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 80px;
}
@media (max-width: 767px) {
  .initial-table {
    height: auto;
    padding-bottom: 48px;
  }
}

#footer {
  clear: both;
  width: 100%;
  position: absolute;
  bottom: 40px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Initial/initial.scss","webpack://./src/common/styles/_mixins.scss"],"names":[],"mappings":"AAEA;EACE,6BAAA;AADF;;AAIA;EACE,0BAAA;EACA,WAAA;EACA,kFAAA;EACA,aAAA;EACA,uBAAA;AADF;ACEE;EDNF;IAQI,6BAAA;IACA,oBAAA;EAAF;AACF;;AAGA;EACE,WAAA;EACA,aAAA;EACA,uBAAA;EACA,oBAAA;AAAF;ACXE;EDOF;IAOI,YAAA;IACA,oBAAA;EACF;AACF;;AAEA;EACE,WAAA;EACA,WAAA;EACA,kBAAA;EACA,YAAA;AACF","sourcesContent":["@import \"../../common/styles/mixins\";\n\n.ant-dropdown-menu {\n  border-radius: 2px!important;\n}\n\n.initial {\n  height: calc(100vh - 50px);\n  width: 100%;\n  background: transparent url(\"../../common/images/rocket.png\") no-repeat 80% 100%;\n  display: flex;\n  justify-content: center;\n\n  @include mobile() {\n    background-position: -15% 90%;\n    background-size: 50%;\n  }\n}\n\n.initial-table {\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  padding-bottom: 80px;\n\n  @include mobile() {\n    height: auto;\n    padding-bottom: 48px;\n  }\n}\n\n#footer {\n  clear: both;\n  width: 100%;\n  position: absolute;\n  bottom: 40px;\n}\n","@mixin font($size: 14px, $weight: 400, $lineHeight: 18px) {\n  font-size: $size;\n  line-height: $lineHeight;\n  font-weight: $weight;\n  font-family: 'Roboto';\n}\n\n@mixin maxHeight() {\n  height: calc(100vh - 7px);\n}\n\n@mixin mobile {\n  @media (max-width: 767px) {\n    @content;\n  }\n}\n\n@mixin tablet {\n  @media (max-width: 1224px) {\n    @content;\n  }\n}\n\n@mixin laptop {\n  @media (max-width: 1440px) {\n    @content;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
