import React, {useRef} from "react";
import { Sheet } from 'react-modal-sheet';
import {Toolbar} from "../toolbar";
import {BottomSheetContentTop} from "./components/bottomSheetContentTop";
import {getDeviceSize} from "common/utils/responsive";
import LoadDataTransfer from "common/utils/loadDataTransfer";
import {useProjectStore} from "store/projects";
import Spinner from "../spinner";
import './bottomSheet.scss'

export const BottomSheetComponent = ({open, onClose, children, graph}) => {
	const {isMobile} = getDeviceSize();
  const parentRef = useRef();
	const { drawerLoader} = useProjectStore(s => s);
  const { isScrolled} = LoadDataTransfer({parentRef})
  return (
		<>
			{(isMobile && !open) && <Toolbar open={open} graph={graph}/>}
			<Sheet className='_sheet' isOpen={open}
						 onClose={onClose}
						 snapPoints={[915, 400, 0]}
						 initialSnap={0}>
				<Sheet.Container>
					{drawerLoader && <div className="spinner-wrapper"><Spinner/></div>}
					<>
						<Sheet.Header className={isScrolled ? 'head-scroll' : 'head-default'}>
							<Sheet.Header/>
							<BottomSheetContentTop onClose={onClose}/>
						</Sheet.Header>
						<Sheet.Content>
							<Sheet.Scroller draggableAt="both" ref={parentRef}>
								{children}
							</Sheet.Scroller>
						</Sheet.Content>
					</>
				</Sheet.Container>
				<Sheet.Backdrop onClick={onClose}/>
			</Sheet>
		</>
	);
};
