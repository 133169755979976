import React, { useRef } from "react";
import cn from "classnames";

import {Toolbar} from "../toolbar";
import CloseMediumIcon from "common/icons/closeMediumIcon";
import LoadDataTransfer from "common/utils/loadDataTransfer";
import "./drawerComponent.scss";

export const DrawerComponent = ({children, open, onClose, graph}) => {
  const parentRef = useRef(null);
  const { isScrolled} = LoadDataTransfer({parentRef})

  return <>
		<Toolbar open={open} graph={graph} />
		<div ref={parentRef}
         className={`drawer-layout drawer-${open ? 'open' : 'close'}`}>
			<div className={cn("drawer-layout__top", {
        _scrolled: isScrolled,
      })}>
				<CloseMediumIcon onClick={onClose}/>
			</div>
			{children}
		</div>
	</>
}
