const IconCrossSharp = () => {
    return (
        <svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.57808 4.99972L8.50776 1.50753C8.55687 1.4495 8.51558 1.36133 8.43968 1.36133H7.54906C7.4966 1.36133 7.44638 1.38477 7.41178 1.42494L4.99549 4.30552L2.57919 1.42494C2.54571 1.38477 2.49549 1.36133 2.44191 1.36133H1.55129C1.4754 1.36133 1.4341 1.4495 1.48321 1.50753L4.4129 4.99972L1.48321 8.49191C1.47221 8.50485 1.46515 8.52067 1.46287 8.53749C1.4606 8.55432 1.4632 8.57145 1.47037 8.58684C1.47753 8.60224 1.48897 8.61525 1.50331 8.62434C1.51766 8.63343 1.53431 8.63821 1.55129 8.63811H2.44191C2.49437 8.63811 2.54459 8.61468 2.57919 8.5745L4.99549 5.69392L7.41178 8.5745C7.44526 8.61468 7.49549 8.63811 7.54906 8.63811H8.43968C8.51558 8.63811 8.55687 8.54995 8.50776 8.49191L5.57808 4.99972Z"
                fill="black"
                fillOpacity="0.45"
            />
        </svg>
    );
};

export default IconCrossSharp;
