import {useEffect, useState} from "react";
import {useProjectStore} from "store/projects";
import {getEdges, getNodes} from "../utils";

export function useAddNewTransfer({graph, projectId}) {
	const { addTransfer, objects } = useProjectStore(s => s);
	const [newTransfer, setTransfer] = useState();

	useEffect(() => {
		if (newTransfer) {
			const sender = newTransfer.sender?.id;
			const recipient = newTransfer.recipient?.id;
			const transferId = newTransfer.id;
			const nodes = getNodes(objects?.transfers, objects.wallets);
			const newEdges = getEdges(objects?.transfers || []);
			// add new wallet to the canvas;
			for (const node of nodes) {
				if (node.id === sender || node.id === recipient) {
					graph.addItem('node', node);
				}
			}
			// add new transfer to the canvas;
			for (const edge of newEdges) {
				if (edge.id === transferId) {
					graph.addItem('edge', edge);
				}
			}
			setTransfer(null);
		}
	}, [newTransfer,objects?.transfers,objects.wallets,graph]);

	const postNewTransfer = async (ext_id) => {
		if (!!projectId && !!projectId) {
			const body = {id: projectId, ext_id, cb: (data) => setTransfer(data)};
			return await addTransfer(body);
		}
	};

	return {
		postNewTransfer,
	}
}
