const nodeBgColors = {
	atm: {
		bgColor: "#6C8714",
		textColor: "#FFF"
	},
	bridge: {
		bgColor: "#DC71EE",
		textColor: "#FFF"
	},
	child_abuse_material: {
		bgColor: "#880E4F",
		textColor: "#FFF"
	},
	custodial_wallet: {
		bgColor: "#5EB98D",
		textColor: "#000"
	},
	darknet_market: {
		bgColor: "#AD1457",
		textColor: "#FFF"
	},
	dex: {
		bgColor: "#CFBC12",
		textColor: "#3E2723"
	},
	enforcement_action: {
		bgColor: "#CA7777",
		textColor: "#FFF"
	},
	exchange: {
		bgColor: "#2FC035",
		textColor: "#FFF"
	},
	fraud_shop: {
		bgColor: "#EC407A",
		textColor: "#FFF"
	},
	gambling: {
		bgColor: "#FF9800",
		textColor: "#FFF"
	},
	high_risk_exchange: {
		bgColor: "#284D2B",
		textColor: "#FFF"
	},
	high_risk_jurisdiction: {
		bgColor: "#F44336",
		textColor: "#FFF"
	},
	iaas: {
		bgColor: "#228FE6",
		textColor: "#FFF"
	},
	ico: {
		bgColor: "#EEE5A2",
		textColor: "#3E2723"
	},
	illegal_service: {
		bgColor: "#D81B60",
		textColor: "#FFF"
	},
	lending: {
		bgColor: "#E4D43E",
		textColor: "#3E2723"
	},
	marketplace: {
		bgColor: "#BBDEFB",
		textColor: "#232325"
	},
	mining: {
		bgColor: "#84FFFF",
		textColor: "#000"
	},
	mining_pool: {
		bgColor: "#00E5FF",
		textColor: "#232325"
	},
	mixer: {
		bgColor: "#4A148C",
		textColor: "#FFF"
	},
	nft_marketplace: {
		bgColor: "#82B1FF",
		textColor: "#232325"
	},
	online_pharmacy: {
		bgColor: "#FF9800",
		textColor: "#FFF"
	},
	other: {
		bgColor: "#BFBFBF",
		textColor: "#232325"
	},
	p2p_exchange: {
		bgColor: "#14871A",
		textColor: "#FFF"
	},
	payment_service_provider: {
		bgColor: "#0D47A1",
		textColor: "#FFF"
	},
	privacy_protocol: {
		bgColor: "#9C27B0",
		textColor: "#FFF"
	},
	ransomware: {
		bgColor: "#724439",
		textColor: "#FFF"
	},
	sanctions: {
		bgColor: "#B71C1C",
		textColor: "#FFF"
	},
	scam: {
		bgColor: "#9D796B",
		textColor: "#FFF"
	},
	seized_funds: {
		bgColor: "#00B8D4",
		textColor: "#FFF"
	},
	smart_contract: {
		bgColor: "#E7EB22",
		textColor: "#3E2723"
	},
	stolen_funds: {
		bgColor: "#3E2723",
		textColor: "#FFF"
	},
	terrorist_financing: {
		bgColor: "#7D0B0B",
		textColor: "#FFF"
	},
	token_contract: {
		bgColor: "#FFF06C",
		textColor: "#3E2723"
	},
	unnamed_service: {
		bgColor: "#ABC3C1",
		textColor: "#232325"
	},
	unnamed_wallet: {
		bgColor: "#B6BDAC",
		textColor: "#232325"
	},
	dust: {
		bgColor: "#DCD5CA",
		textColor: "#232325"
	},
	undefined: {
		bgColor: "#EDEDED",
		textColor: "#232325",
	},
	default: {
		bgColor: "#fff",
		textColor: '#232323',
		descriptionColor: '#626368',
	}
};

export function setNodeBackground(value) {
	return {
		bgColor: value ? nodeBgColors[value]?.bgColor : nodeBgColors.default.bgColor,
		stroke: nodeBgColors[value]?.bgColor,
		textColor: value ? nodeBgColors[value]?.textColor : nodeBgColors.default.textColor,
		descriptionColor: value ? nodeBgColors[value]?.textColor : nodeBgColors.default.descriptionColor,
	}
}
